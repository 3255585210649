import { useContext } from "react";
import OrganizerInput from "../../widgets/OrganizerInput";
import { UserContext } from "../../../providers/UserProvider";
import { useNavigate } from "react-router-dom";

export default function AdminSelectOrganizer() {
    const navigate = useNavigate();
    const {setOrganizerId} = useContext(UserContext);

    const submit = (id) => {
        setOrganizerId(id);
        setTimeout(() => navigate('/dashboard'), 200);
    }

    return (
        <div style={{width:"calc(100% - 40px)",height:"calc(100% - 40px)"}}className="col-flex flex-gap-2rem align_center justify_center p-20 ">
            <div className="fontSize-30px text-color__main text-center">Wähle aus, als welchen Veranstalter du dir das Dashboard anschauen willst.</div>
            <div>
                <OrganizerInput className="TextInputModal min-w-250p" onSelect={submit} />
            </div>
        </div>
    );
}