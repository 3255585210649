import React, { useEffect, useState } from 'react'
import { ReactComponent as IconAnimated } from '../../graphics/icon_elgio_animated.svg';  // Importing SVG


let loaderVisible = false

export const showLoader = (id) => {
  if(id){
    document.getElementById(`loaderDiv_${id}`).classList.remove("v-hide")
    document.getElementById(`loaderDiv_${id}`).classList.add("v-show")
    loaderVisible = true
  }
  else if(document.getElementsByClassName("loaderDivNew").length > 1)
    console.error("Multiple loaders found in the DOM!\nPlease use the 'id' parameter if more than one loader is needed in one Component.")
  else{
    document.getElementsByClassName("loaderDivNew")[0].classList.add("v-show")
    loaderVisible = true
  }
}

export const hideLoader = (id) => {
  if(id){
    document.getElementById(`loaderDiv_${id}`).classList.remove("v-show")
    document.getElementById(`loaderDiv_${id}`).classList.add("v-hide")
    loaderVisible = false
  }
  else if(document.getElementsByClassName("loaderDivNew").length > 1)
    console.error("Multiple loaders found in the DOM!\nPlease use the 'id' parameter if more than one loader is needed in one Component.")
  else{
    document.getElementById(`loaderDiv_${id}`).classList.remove("v-show")
    document.getElementsByClassName("loaderDivNew")[0].classList.add("v-hide") 
    loaderVisible = false
  }
}


export default function LoaderDivWithOverlay({id}) {
  const [dotCount,setDotCount] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount(prevCount => (prevCount === 2 ? null : prevCount === null ? 0 : prevCount + 1));
    }, 1500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div id={`loaderDiv_${id}`} className="loaderDivNew col-flex justify_center align_center z-index-999" style={{padding:"15px",paddingTop:"10px",gap:"1rem",backgroundColor: "var(--bg2)"}}>    
    <div
      style={{
        zIndex: "999",
        color: "white",
        marginTop:"20px",
        display:"flex",
        flexDirection:"row",
      }}
    >
      {loaderVisible && IconAnimated && <IconAnimated className="loader" id="loader" />}
    </div>
        {loaderVisible && <div style={{marginLeft:"15px", marginRight:"15px", color: "var(--textMain)"}}>
          Loading
          <span className={`${dotCount !== null ? "v-show" : "v-hide"}`}>.</span>
          <span className={`${![null,0].includes(dotCount) ? "v-show" : "v-hide"}`}>.</span>
          <span className={`${![null,0,1].includes(dotCount)  ? "v-show" : "v-hide"}`}>.</span>
        </div>}
    </div>
    )
}

