import React, { useMemo, useState } from 'react'
import { useEffect } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import "../../../index.scss";
import "./formSuccess.css";
import "../events/event-erstellen.css";

import { chevronDownIcon, plusIcon } from '../../../icons';
import kitLogo from "../../../graphics/kit_logo.png";
import logo from "../../../graphics/newIcon_white.png";
import { Helmet } from 'react-helmet';
import { collection, doc, getDoc, getDocs, orderBy, query } from 'firebase/firestore';
import { firestore } from '../../../firebase';
import { PurchaseMarketingBundleModal } from '../ticketing/purchase-marketing-bundle';

export default function FormSuccess() {
    const [partner, setPartner] = useState(null);
    const [searchParams] = useSearchParams();
    const [visibleDetails, setVisibleDetails] = useState({});

    //purchase bundle modal
    const [eventIdSearchParam, setEventId] = useState(null);
    const { id } = useParams();
    const eventId = useMemo(() => id || eventIdSearchParam, [id, eventIdSearchParam]);
    const [bundleId, setBundleId] = useState(null);
    const redirectedFromDashboard = useMemo(() => searchParams.get('redirected_from_dashboard') === 'true', [searchParams]);
    const pureBundleBooking = useMemo(() => id || redirectedFromDashboard, [id, redirectedFromDashboard]);

    //payment stuff
    const [paymentId, setPaymentId] = useState(null);
    const [payment, setPayment] = useState(null);
    useEffect(() => {
        if(!paymentId) return;
        getDoc(doc(firestore, 'payments', paymentId)).then((snapshot) => {
            setPayment(snapshot);
        });
    }, [paymentId]);
    const paymentConfirmed = useMemo(() => searchParams.get('redirect_status') === 'succeeded' || payment?.data().status === 'confirmed', [payment, searchParams]);
    const paymentDeclined = useMemo(() => searchParams.get('redirect_status') === 'failed' || payment?.data().status === 'declined', [payment, searchParams]);
    useEffect(() => {
        if(!paymentDeclined) return;
        if(!payment) return;
        if(!searchParams.get('open_payment')) return;
        if(bundleId) return;
        getDoc(doc(firestore, 'marketing_bundle_bookings', payment.data().marketing_bundle_booking)).then((snapshot) => {
            setBundleId(snapshot.data().marketing_bundle);
        });
    }, [paymentDeclined, payment, bundleId, searchParams]);

    //bundles
    const [bundles, setBundles] = useState(null);
    useEffect(() => {
        getDocs(query(collection(firestore, 'marketing_bundles'), orderBy('order'))).then((snapshot) => {
            setBundles(snapshot.docs);
        });
    }, []);

    useEffect(() => {
        if (searchParams.get('partner')) {
            setPartner(searchParams.get('partner'));
        }
        if (searchParams.get('event')) {
            setEventId(searchParams.get('event'));
        }
        if (searchParams.get('payment')) {
            setPaymentId(searchParams.get('payment'));
        }
    }, [searchParams]);

    const toggleMoreDetails = (index) => {
        setVisibleDetails(prevState => {
            return { ...prevState, [index]: !prevState[index] };
        });
    };

    return <>
        <Helmet>
            <meta name="robots" content="noindex" />
        </Helmet>
        <PurchaseMarketingBundleModal eventId={eventId} bundleId={bundleId} showModal={!!bundleId} setShowModal={e => !e && setBundleId(null)} setBundleId={setBundleId} />
        <div className='event-form-wrapper-success'>
            {partner === 'koelnisttechno'
                ? <div className='formSuccessPage'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
                        {partner === 'koelnisttechno' ? <img style={{ height: 40, width: 40 }} src={kitLogo} alt="kit logo" /> : <div />}
                        <img style={{ height: 40 }} alt="elgio icon" src={logo} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <div className='formSuccessTitle'>Perfekt, dein Event wurde abgeschickt!</div>
                        <div className='formSuccessSubtitle'>Nach einer kurzen Überprüfung wird das Event freigegeben und hochgeladen.</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <Link to={"/event-form"} className="oneMoreEvent">{plusIcon} Weiteres Event hochladen</Link>
                    </div>
                </div>
                : !paymentConfirmed ? <div className='formSuccessPage' style={{ justifyContent: "normal" }}>
                    <div style={{ display: 'flex', justifyContent: "flex-end", marginBottom: 10 }}>
                        <img style={{ height: 40 }} alt="elgio icon" src={logo} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        {pureBundleBooking ? <div className='formSuccessTitle'>So kannst du dein Event bewerben</div> : <div className='formSuccessTitle'>Perfekt, dein Event wurde abgeschickt!</div>}
                        <div className='formSuccessSubtitle' style={pureBundleBooking ? {display: "none"} : {display: "flex"}}>Nach einer kurzen Überprüfung wird das Event freigegeben und hochgeladen.</div>
                    </div>
                    <div style={{ marginTop: "90px" }}>
                        <div className='promotionIntrosentence' style={pureBundleBooking ? {display: "none"} : {display: "flex"}}>So kannt du dein Event weiter bewerben:</div>
                        <div className='promotionMainBox'>
                            {bundles && bundles.map((bundle) => <div key={bundle.id} className='promotionBox'>
                                <div className='promotionHeader'>
                                    <div className="dealTitle">{bundle.data().name}</div>
                                    <div className='dealNumber'>{(bundle.data().price / 100).toFixed(2).replace('.', ',')}€</div>
                                </div>
                                <div className='dealSubtitle'>{bundle.data().short_description}</div>
                                <div className='moreDealDetails' onClick={() => toggleMoreDetails(bundle.id)}>Mehr Infos {chevronDownIcon}</div>
                                <div className={`moreDetailsBox ${visibleDetails[bundle.id] ? 'visible' : 'hidden'}`}>
                                    {bundle.data().details?.map((line, index) => <div key={index}> · {line}</div>)}
                                </div>
                                <button className='bookDealButton' onClick={() => setBundleId(bundle.id)}>Bundle wählen</button>
                            </div>)}
                        </div>
                    </div>
                    {!pureBundleBooking && <div style={{ display: "flex", flexDirection: "column", gap: "5px", marginTop: "auto" }}>
                        <Link to={"/event-form"} className="oneMoreEvent">{plusIcon} Weiteres Event hochladen</Link>
                    </div>}
                </div> : <div className='formSuccessPage'>
                    <div style={{ display: 'flex', justifyContent: "flex-end", marginBottom: 10 }}>
                        <img style={{ height: 40 }} alt="elgio icon" src={logo} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <div className='formSuccessTitle'>Vielen Dank für deine Bestellung!</div>
                        <div className='formSuccessSubtitle'>Deine Bestellung wurde erfolgreich abgeschlossen.</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <Link to={"/event-form"} className="oneMoreEvent">{plusIcon} Weiteres Event hochladen</Link>
                    </div>
                </div>
            }
        </div>
    </>;
}