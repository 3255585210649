/* eslint-disable react-hooks/exhaustive-deps */
import {  
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Erstellen from "./components/pages/login_signup/account_erstellen";
import Zwischenseite from "./components/pages/login_signup/zwischenseite";
import FastFertig from "./components/pages/login_signup/fast_fertig";
import Login from "./components/pages/login_signup/login";
import ResetPassw from "./components/pages/login_signup/reset_passw";
import { EventForm } from "./components/pages/events/event-erstellen";
import { UserContext } from "./providers/UserProvider";
import { Fragment, useContext, useEffect } from "react";
import PrivateRoute from "./components/functions/PrivateRoute";
import React from "react";
import { Loader } from "./loader";
import NotFound from "./components/pages/special/404";
import { collection, doc, getDoc } from "firebase/firestore";
import { firestore } from "./firebase";
import Logout from "./components/functions/logout";
import FormSuccess from "./components/pages/special/formSuccess";
import Dashboard from "./dashboard";
import { Helmet } from "react-helmet";
import PromoteEventSelect from "./components/pages/special/selectEvent";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./components/pages/special/error";
import AdminSelectOrganizer from "./components/pages/login_signup/admin_select_organizer";

export let isTestUser = false

function SiteRoutes(){
  const {organizerId} = useContext(UserContext)
  const navigate = useNavigate()
  useEffect(() => {
    organizerId ? 
      getDoc(
        doc(collection(firestore, "organizers"), organizerId)
      )
        .then((snapshot) => {
          if(snapshot.data()?.role === "admin"){
            isTestUser = true
          }
          else{
            isTestUser = false
          }

        })
        .catch((error) => console.log(error))
  : console.log("No User logon")
  },[organizerId])
  useEffect(() => {
    let isRedirected = false
    if (organizerId !== null) {
      getDoc(doc(firestore, "organizers", organizerId)).then((organizer) => {
        if (organizer.data()?.show_fusion  && !isRedirected) {
          navigate("/dashboard/fusion-info")
          document.getElementById('root').style.backgroundColor='var(--dark)'
          isRedirected = true
        }
      })
    }
  }, [organizerId]);
  


  return(
    <Routes>
      {/* <Route path="/test" element={<Loadingtest></Loadingtest>}></Route> */}
      <Route path="/event-form" element={
        <>
          <Helmet>
            <title>ELGIO-Dashboard  Event Form</title>
          </Helmet>
          <EventForm/>
        </>
      }/>
      <Route path="/event-form/success" element={
        <>
          <Helmet>
            <title>ELGIO-Dashboard  Event Form Success</title>
          </Helmet>
          <FormSuccess />
        </>
      }/>
      <Route path="/promote-event" element={
        <>
          <Helmet>
            <title>Event bewerben - Eventauswahl - ELGIO</title>
          </Helmet>
          <PromoteEventSelect />
        </>
      }/>
      <Route path="/promote-event/:id" element={
        <>
          <Helmet>
            <title>Event bewerben - ELGIO</title>
          </Helmet>
          <FormSuccess />
        </>
      }/>
      <Route path="/register/landing" element={
        <>
          <Helmet>
            <title>ELGIO-Dashboard Informationen </title>
          </Helmet>
          <Zwischenseite/>
        </>
        }/>
      <Route path="/register" element={
        <>
          <Helmet>
            <title>ELGIO-Dashboard Profil erstellen </title>
          </Helmet>
          <FastFertig/>
        </>        
        }/>
      <Route path="/register/create-profile" element={<PrivateRoute ignoreProfileNotYetCreated={true}/>}>
        <Route path="/register/create-profile" element={
          <>
            <Helmet>
              <title>ELGIO-Dashboard Profil erstellen </title>
            </Helmet>

            <Erstellen/>
          </>        
        }/>
      </Route>
      <Route path="/login" element={
        <>
        <Helmet>
          <title>ELGIO-Dashboard Login </title>
        </Helmet>
        <Login/>
        </>
      }/>
      <Route path="/login/reset-password" element={
      <>
        <Helmet>
          <title>ELGIO-Dashboard Passwort zurücksetzen </title>
        </Helmet>
        <ResetPassw/>
      </>
      }/>
      <Route path="/login/admin" element={<PrivateRoute ignoreProfileNotYetCreated={true}/>}>
        <Route path="/login/admin" element={
          <>
            <Helmet>
              <title>ELGIO-Dashboard Admin Select </title>
            </Helmet>

            <AdminSelectOrganizer/>
          </>        
        }/>
      </Route>
      <Route path="/" element={
        <>
          <Helmet>
            <title>ELGIO-Dashboard Informationen </title>
          </Helmet>
        
          <Zwischenseite/>
        </>
        }/>
      <Route path="*" element={
        <>
          <Helmet>
            <title>ELGIO-Dashboard 404 </title>
          </Helmet>
          <NotFound/>
        </>
        }/>
      
      <Route path="/logout" element={<PrivateRoute/>}>
        <Route path="/logout" element={
        <>
          <Helmet>
            <title>ELGIO-Dashboard Logout </title>
          </Helmet>

          <Logout/>
        </>
        }/>  
      </Route>
      <Route path="/dashboard/*" element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      } />
    </Routes>
  )
}


function App() {
  const { loaded } = useContext(UserContext);
  
  useEffect(() => {
    document.getElementsByClassName("loaderDiv")[0].style.visibility="visible";
  },[])

  return (
    <ErrorBoundary fallbackRender={({error}) => <ErrorPage error={error} />}>
      {loaded ? 
        <Fragment>
          <SiteRoutes></SiteRoutes>
        </Fragment>
      : 
        <Loader></Loader>}
    </ErrorBoundary>
  );
}

export default App;
