/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../index.scss";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";
import ProgressBar  from "@ramonak/react-progress-bar";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale, //x axis
  LinearScale, //y axis
  PointElement,
  Tooltip,
  Title,
  Filler
} from 'chart.js';
import { StatsContext } from "../../../providers/StatsProvider";
import Modal from 'react-modal'
import EventBox from "../../widgets/eventbox";
import {
  cancelIcon,
  chevronLeftIcon,
  chevronRightIcon,
  plusIcon,
  searchIcon,
} from "../../../icons.jsx";
import LineGraph from "../../widgets/statistics_widgets/lineGraph";
import { ModalTicketing } from "./ticketing_anlegen.jsx";
import Success from "./success.jsx";


function TicketingEventBox(props) {
  const [ticketanzahl,setTicketanzahl] = useState(0)
  const [ticketKaeufe,setTicketKaeufe] = useState(0)
  const [percentage,setPercentage] = useState(0)
  const [ticketSalesNum, setTicketSalesNum] = useState(0)
  const [tickets,setTickets] = useState([])
  
  useEffect(() => {
    getDocs(
      query(
        collection(firestore,"tickets"),
        where("event", "==", props.id),
        where("status", "in", ["redeemed", "unredeemed", "confirmed"]),
        where("type","!=","guestlist")
      )).then((tickets) => {
        setTickets(tickets.docs)
        setTicketSalesNum(tickets.docs.reduce((prev, curr) => prev + curr.get('price'), 0))
      })
  },[props.id])

  useEffect(() => {
    getDocs(collection(firestore,"events",props.id,"tickets"))
        .then((ticketTypesEventSnap) => {
          const anzahl = ticketTypesEventSnap.docs.reduce((prev, curr) => prev + curr.data()?.total_tickets??0, 0)
          const kaeufe = tickets.length;
          setTicketanzahl(anzahl)
          setTicketKaeufe(kaeufe)
          setPercentage(kaeufe / anzahl * 100);
        })
  },[props.id, tickets])

  const {loaded: statsLoaded, getTicketSales} = useContext(StatsContext);
  const [timeframe, setTimeframe] = useState('week');
  const [ticketSalesSums] = getTicketSales({ timeframe, event: props.id });
  const numberOfStates = 4;
  const [ticketSalesState,setTicketSalesState] = useState(2)
  const [ticketSalesActiveText,setTicketSalesActiveText] = useState("Letzte 7 Tage")
  
  useEffect(() => {
    if(ticketSalesState === 0){
      setTimeframe('today')
       setTicketSalesActiveText("Heute")
    }
   else if(ticketSalesState === 1){
    setTimeframe('yesterday')
       setTicketSalesActiveText("Gestern")
   }
   else if(ticketSalesState === 2){
    setTimeframe('week')
       setTicketSalesActiveText("Letzte 7 Tage")      
   }
   else if(ticketSalesState === 3){
    setTimeframe('last_30_days')
       setTicketSalesActiveText("Letzte 30 Tage")      
   }
 },[ticketSalesState,statsLoaded])

  
    ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Title,
    Filler
    )

  let titelShort;
  if(props.titel.length > 35){
    titelShort = props.titel.slice(0, 35)
    titelShort = titelShort.concat("...")  
  }

  const dateToString = (date) => date.toLocaleDateString("de-DE") + " um " + date.toLocaleTimeString('de-DE').slice(0,5) + " Uhr";


  return (
    <Link to={"/dashboard/events/event/" + props.id} onClick={() => window.scrollTo({ top: 0 })} className="TicketingEventBox">
      <div className="TicketingEventBoxContent">
        <div className="TicketingEventBoxRowLeft">
          <div className="TicketingEventBoxFirstRow">
            <div className="eventDetailsText">{props.titel.length > 35 ? titelShort : props.titel ?? "Loading..."}</div>
            <div className="LastSales"> 
              {tickets.length === 0 ? <>
                Noch keine Tickets verkauft
              </> : <>
                Letzter Verkauf am {dateToString(tickets.reduce((prev, curr) => curr.get('confirmed_timestamp')?.toDate() > prev ? curr.get('confirmed_timestamp').toDate() : prev, new Date(0)))}
              </>}
            </div>
          </div>
          <div className="TicketingEventBoxSecondRow">
            <div className="priceForNaN" style={{fontSize: "var(--fontsize-massive)", fontWeight: "600", color: "var(--textMain)"}}>
                {(ticketSalesNum / 100).toFixed(2).replace('.', ',')}  
            </div>
            <ProgressBar margin="11px 0px 5px 0px"  completed={percentage} isLabelVisible={false} width={"20vw"} height={"5vh"} borderRadius={10} bgColor={"var(--blue)"} baseBgColor={"var(--grey)"}></ProgressBar>
            <div style={{textAlign: "end", fontSize: "var(--fontsize-small)", color: "var(--textMain)"}}>{ticketKaeufe} / {ticketanzahl} ({percentage.toFixed(0) !== "NaN" ? percentage.toFixed(0) : "0"} %)  </div>
          </div>
        </div>
        <div className="TicketingEventBoxRowRight">
          <div className="chart-box-ticketing">
            <div className="chart-box-ticketing-content">
              <div className="row-flex justify_space_around align_center" style={{width: "100%", marginBottom: "10px"}} onClick={(e) => {e.preventDefault()}}>
                <div style={{color: "var(--textSecondary)"}} onClick={() => {setTicketSalesState((ticketSalesState+(numberOfStates-1)) % numberOfStates)}}>{chevronLeftIcon}</div>
                <div className="Montserrat_600 fontSize-16px white">{ticketSalesActiveText}</div>
                <div style={{color: "var(--textSecondary)"}} onClick={() => {setTicketSalesState((ticketSalesState+1) % numberOfStates)}}>{chevronRightIcon}</div>
              </div>
              <LineGraph data={ticketSalesSums} style={{height:"85%",width:"90%"}} colors={['green', 'blue', 'red']}/>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default function Ticketing() {
  const { permissions } = useContext(UserContext);
  const navigate = useNavigate();
  const [upcoming,setUpcoming] = useState(true);
  const [allEvents, setAllEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const { organizerId } = useContext(UserContext);
  const el = Modal.setAppElement("#portal")
  const [show,setShow] = useState(false)
  const [search,setSearch] = useState("")
  const [eventsModal,setEventsModal] = useState([])
  const [ticketingAnlegenData,setTicketingAnlegenData] = useState({});
  const [showSuccess,setShowSuccess] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [lowestPrice,setLowestPrice] = useState(0)  
  const [showModalTicketingAnlegen,setShowModalTicketingAnlegen] = useState(false)
  const [id,setId] = useState(0)
  useEffect(() => {
      getDocs(query(
        collection(firestore,"events"),
        where("organizer","array-contains",organizerId),
        where("start", ">", new Date()),
        where("visibility", "in", ["public" , "draft"]))
      ).then((events) => {
          setEventsModal(events.docs)
        })
  },[])
      
  const searchQuery = async (e) => {
    await getDocs(query(
      collection(firestore,"events"),
      where("organizer","array-contains",organizerId),
      where("name",">=",e.target.value)),
      where("start", ">", new Date()),
      where("name","<=",(e.target.value+ "\uf8ff")),
      where("visibility","in",["public","draft"])
      ).then((events) => {
      setEventsModal(events.docs)
    })
  }

  const fetchUpcomingEvents  = async () => {
    setEvents([])
    await  getDocs(
      query(
        collection(firestore, "events"),
        where("organizer", "array-contains", organizerId),
      where("start",">",new Date()),
      where("visibility", "in", ["public", "private"]),
      orderBy("start","asc"),
    )
    ).then((events) => {
      setAllEvents(events.docs)
    })
  }

  const fetchPastEvents  = async () => {
      setEvents([])
    await  getDocs(
        query(
        collection(firestore, "events"),
        where("organizer", "array-contains", organizerId),
        where("start","<",new Date()),
        where("visibility", "in", ["public", "private"]),
        orderBy("start","desc"),
      )
    ).then((events) => {
      setAllEvents(events.docs)
    })
  }

  useEffect(() =>{
      fetchUpcomingEvents()
  },[])

  useEffect(() => {
    if(allEvents.length === 0){
      return
    }
    else{
      for (const event in allEvents ) {
          const element = allEvents[event];
          const elementHasTicketing = element.data().ticket
          
          if(["standard", "hidden","completed"].includes(elementHasTicketing)) setEvents((prevArray) => [...prevArray, element]) 
        }
      }
  },[allEvents])
    
  useEffect(() => {
      if(events.length === 0){
        return
      }
      else{
      }
    },[events])

    const handleTicketingModal = (data,eventID) => {
      if(data){
        setShow(false)
        setTicketingAnlegenData(data)
        setId(eventID)
        setShowModalTicketingAnlegen(true)
      }
    }

  return (

    <div className="content">
      <div id="breadcrumb-title-text" onClick={() => navigate(-1) }>Ticketing</div>
      <div className="PageHeaderBox">
        <div>
          <div className="PageHeaderBoxTitle">Ticketing</div>
          <div className="PageHeaderBoxSubtitle">Hier hast du eine Übersicht über alle Events von dir, die ELGIO Ticketing aktiviert haben.</div>
        </div> 
        {permissions?.events?.edit && <div onClick={() => {setShow(true)}} id="newEventButton" style={{textAlign:"start"}}>
          <div>{plusIcon}</div>
            Für neues {<br></br>}
            Event aktivieren
        </div>}
      </div>
      <div className="EventBoxColumn">
        <div className="title-row">
          <div className={upcoming ? "beschreibungtitelActive" : "beschreibungtitelNotActive"} onClick={() => {setUpcoming(true);fetchUpcomingEvents()}}>Anstehende Events</div>
          <div className="beschreibungtitel">|</div>
          <div className={upcoming ? "beschreibungtitelNotActive" : "beschreibungtitelActive"} onClick={() => {setUpcoming(false);fetchPastEvents()}}>Vergangene Events</div>
        </div>
        <div className={"EventBoxTicketing"} style={events.length === 0 ? {justifyContent:"center",alignItems:"center",width:"100%"}  : {}}>
          { events.length === 0 && <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <div id="noUpcoming" className="animate__animated animate__fadeInDown" style={ events.length === 0 ?  {display:"block", color: "var(--textSecondary)"} : {display:"none"}}>{upcoming ? "Du hast ELGIO-Ticketing bei keinem deiner anstehenden Events aktiviert" : "Du hast ELGIO-Ticketing bei keinem deiner vergangenen Events aktiviert"}</div>
            <div id="noUpcoming" className="animate__animated animate__fadeInDown" style={ events.length === 0 ?  {display:"block", color: "var(--textSecondary)"} : {display:"none"}}>Entwürfe werden hier auch nicht berücksichtigt</div>
          </div>}
          {events.map((event) => {
            return (
              <div className="ticketingEvent">
                <TicketingEventBox
                  id={event.id}
                  key={event.id}
                  ticketanzahl={event.data().total_tickets}
                  ticketKaeufe={event.data().ticket_sales}
                  salesSum={event.data().salesSum}

                  // TODO: add right ticketSales Array
                  // ticketSalesMonth={event.data().ticketsales_month ?? []}
                  // ticketSalesDay={event.data().ticketsales_day ?? []}
                  date={event.data()?.start?.toDate() && (event.data().start.toDate().toLocaleDateString("de-DE", {weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric'})
                  + ' ab ' + 
                  event.data().start.toDate().toLocaleTimeString("de-DE", {hour: '2-digit', minute: '2-digit'})
                  + ' Uhr')}
                  titel={event.data().name}
                ></TicketingEventBox>
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        isOpen={show}
        appElement={el}
        unselectable="true"
        autoFocus={false}
        className="modalContentNormal"
        overlayClassName="modalOverlayNormal"
        shouldFocusAfterRender={false}
        shouldCloseOnOverlayClick={true}
        onRequestClose={()=>{setShow(false)}}
        style={{
          content: {
              backgroundColor: "var(--bg2)",
              width: "60vw",
              height: "fit-content",
              padding: "25px 35px",
              zIndex: "4",
      }}}>
        <div className="col-flex" style={{gap:"2rem", height:"100%"}}>
          <div className="col-flex">
            <div className="row-flex" style={{justifyContent:"space-between",alignItems:"center"}}>
              <div style={{fontWeight: "600", fontSize: "var(--fontsize-humongous)", color: "var(--textMain)"}}>Event auswählen</div>
              <div style={{color: "var(--textMain)", fontSize: "var(--fontsize-big)", cursor:"pointer"}} onClick={() => {setShow(false)}}>{cancelIcon}</div>
            </div>
            <div className="row-flex align_center">
              <div style={{color:"var(--lightgrey)", fontSize: "var(--fontsize-big)"}}>
                Wähle das Event aus, für das du ELGIO Ticketing aktivieren willst.
              </div>
            </div>
          </div>
          <div className="row-flex align_center">
            <div style={{color: "var(--textSecondary)", position: "relative", left: "3.5%"}} onClick={() => {setShow(false)}}>{searchIcon}</div>
            <input type="text" className="TextInput" style={{marginRight:"auto",width:"100%",textIndent:"55px"}} value={search} onChange={(e) => {setSearch(e.target.value);searchQuery(e)}} placeholder="Suche"/>
          </div>
          {eventsModal.length !== 0 
            ? <div className="col-flex" style={{gap:"2rem",overflowY:"auto",maxHeight:"40vh"}}>
              {eventsModal.map((event) => {
                return(
                  <EventBox
                  shouldntRedirect={true}
                  onClick={handleTicketingModal}
                  noContextMenu
                  eventData={event.data()}
                  id={event.id}
                  key={event.id}
                  date={event.data()?.start?.toDate() && (event.data().start.toDate().toLocaleDateString("de-DE", {weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric'})
                  + ' ab ' + 
                  event.data().start.toDate().toLocaleTimeString("de-DE", {hour: '2-digit', minute: '2-digit'})
                  + ' Uhr')}
                  titel={event.data().name}
                  club={event.data().venue}
                  address={event.data().address}
                  banner={
                    (event.data().images?.length ?? 0) > 0
                    ? event.data().images[0]
                    : null}
                    visibility={event.data().visibility}
                    ></EventBox>
                )
              })}
              </div>
            : <div id="noUpcoming" style={{display:"block",color: "var(--textSecondary)", textAlign: "center"}}>Du hast keine anstehenden Events oder Entwürfe für die du Ticketing aktivieren kannst</div>
          }
        </div>
      </Modal>
      <ModalTicketing 
        setShowSuccess={setShowSuccess}
        setData={setTicketingAnlegenData}
        setLowestPrice={setLowestPrice}
        startDate={ticketingAnlegenData?.start?.toDate()}
        startTime={ticketingAnlegenData?.start ? ticketingAnlegenData?.start.toDate().toLocaleTimeString('de-DE').slice(0,5) + " Uhr" : ''} 
        venue={ticketingAnlegenData.venue}
        location={ticketingAnlegenData.address}
        eventName={ticketingAnlegenData.name}
        showModal={showModalTicketingAnlegen}
        setShowModal={setShowModalTicketingAnlegen}
        eventID={id}
      ></ModalTicketing>
      <Success showSuccess={showSuccess} setShowSuccess={setShowSuccess}></Success>
    </div>
  );
}