import { collection, doc, onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { firestore, functions } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import { httpsCallable } from "firebase/functions";

export default function BankInput() {
    const { organizerId } = useContext(UserContext) 

    const [paymentsDoc, setPaymentsDoc] = useState(null);
    const [iban, setIban] = useState('');
    const [editBankAccount, setEditBankAccount] = useState(false);
    const [accountHolder, setAccountHolder] = useState('');
    const [loadingBankAccountEdit, setLoadingBankAccountEdit] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        onSnapshot(doc(collection(doc(collection(firestore, 'organizers'), organizerId), 'private'), 'payments'), setPaymentsDoc);
    }, [organizerId]);

    useEffect(() => {
        if (paymentsDoc && editBankAccount === null) {
            setEditBankAccount(!paymentsDoc.data()?.bank_account?.id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentsDoc]);

    const validateIBAN = (iban) => {
        // Move the first four characters to the end of the string
        let rearrangedIban = iban.slice(4) + iban.slice(0, 4);
    
        // Replace each letter in the string with two digits
        let numericIban = '';
        for (let i = 0; i < rearrangedIban.length; i++) {
            let char = rearrangedIban.charAt(i);
            if (isNaN(char)) {
                numericIban += (char.charCodeAt(0) - 55).toString(); // A = 10, B = 11, ..., Z = 35
            } else {
                numericIban += char;
            }
        }
    
        // Compute the remainder of the number on division by 97
        let checksum = 0;
        for (let i = 0; i < numericIban.length; i++) {
            checksum = (checksum * 10 + parseInt(numericIban.charAt(i))) % 97;
        }
    
        // Validate the checksum
        return checksum === 1;
    };
  
    const saveAccount = async () => {
        
        if (!iban || !accountHolder) {
            setErrorMessage('Bitte fülle alle Felder aus.');
            return;
        }

        const ibanCheck = iban.toUpperCase().replace(/ /g, '').match(/DE\d{20}/);
        if (!ibanCheck) {
            setErrorMessage('Die IBAN muss mit DE beginnen und 20 Zahlen enthalten.');
            return;
        }

        if (!validateIBAN(iban.toUpperCase().replace(/ /g, ''))) {
            setErrorMessage('Die IBAN ist ungültig.');
            return;
        }

        setLoadingBankAccountEdit(true);
        const updateBankAccount = httpsCallable(functions, "payments-updateBankAccount");
        try {
            await updateBankAccount({
                accountNumber: iban.toUpperCase().replace(/ /g, ''),
                accountHolder,
            });
            setEditBankAccount(false);
        } catch (e) {
            alert('Es gab einen Fehler beim Speichern des Kontos. Bitte versuche es erneut.');
        }
        setLoadingBankAccountEdit(false);
    };

    //reset error message on new input
    useEffect(() => {
        setErrorMessage(null);
    }, [iban, accountHolder]);
  
    return(
        <>
            {editBankAccount ? (
                <>
                    <div className="flexBoxBank">
                        <div className="flexBoxBankMini">
                            <div className="t-white"> IBAN </div>
                            <input
                                className="TextInputModal"
                                value={iban}
                                onChange={(e) => setIban(e.target.value.toLocaleUpperCase())}
                                placeholder="DE00 0000 0000 0000 0000 00"
                            />
                        </div>
                        <div className="flexBoxBankMini">
                            <div className="t-white"> Kontohalter </div>
                            <input
                                className="TextInputModal"
                                value={accountHolder}
                                onChange={(e) => setAccountHolder(e.target.value)}
                                placeholder="Max Mustermann"
                            />
                        </div>
                        <div className="flexBoxButtons">
                            <div className="submitChangeBtn" disabled={loadingBankAccountEdit} onClick={saveAccount}>
                                {loadingBankAccountEdit ? 'Bitte warten...' : 'Konto speichern'}
                            </div>
                            {paymentsDoc?.data()?.bank_account?.id && (
                                <div className="cancelPlan" style={{ width: "120px", height: "17px" }} onClick={() => setEditBankAccount(false)}>
                                    Abbrechen
                                </div>
                            )}
                        </div>
                    </div>
                    {errorMessage && <div className="mt-10p text-color__red">{errorMessage}</div>}
                </>
            ) : (
                <div className="bankRow">
                <div className="rowItemLeft medium t-white"> {paymentsDoc?.data()?.bank_account?.bank} </div>
                <div className="bankRowRight rowItemRight">
                    {paymentsDoc?.data()?.bank_account?.last4 && (
                    <div className="planDetails">
                        {paymentsDoc?.data()?.bank_account?.account_holder} · DE** **** **** **** **{paymentsDoc?.data()?.bank_account?.last4?.substring(0, 2)} {paymentsDoc?.data()?.bank_account?.last4?.substring(2, 4)}
                    </div>
                    )}
                    <div className="cancelPlan" onClick={() => setEditBankAccount(true)}>Konto ändern</div>
                </div>
                </div>
            )}
        </>
    );
}