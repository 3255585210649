import React, { useEffect, useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import "../../index.scss";
import "./ArtistEdit.css";
import FirebaseStorageImage from "../functions/FirebaseStorageImage";

export default function ArtistDetails({ id, alt, ...rest }) {
    const [name, setName] = useState('Loading...');
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (id) {
            const artistRef = doc(collection(firestore, 'artists'), id);
            
            getDoc(artistRef)
                .then((doc) => {
                    const data = doc.data();
                    const artistName = data?.name ?? alt ?? '';
                    const artistImage = data?.images?.[0] ?? '';
    
                    setName(artistName);
                    setImage(artistImage);  // Assuming you have setImage to store the image URL
                });
        } else {
            setName(alt ?? '');
            setImage('');  // Reset image if no id
        }
    }, [alt, id]);
    
    return (
        <div {...rest} className="row-flex align_center gap-2">
            <FirebaseStorageImage reference={image} alt={name} className="icon-artist-detail"/>
            <div>{name}</div>
        </div>
    );
}