import React, { useState } from 'react'
import Modal from 'react-modal'
import { addDoc, collection, deleteDoc, doc, onSnapshot, query, updateDoc, where } from 'firebase/firestore'
import { firestore } from '../../../firebase'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import "../../../index.scss";
import "./scanner-hinzufuegen.css";
import { cancelIcon,  minusIcon, plusIcon } from '../../../icons'
import QRScanner from "../../../graphics/ELGIO Scanner.png";

export default function ScannerHinzufuegen({show,handleClose}) {
  const {id} = useParams()
  const [scanner,setScanner] = useState([])
  const el = Modal.setAppElement("#portal")
  const [os,setOS] = useState(null)
  
  useEffect(() => {
    setOS(navigator.userAgent.includes("Mac","iPad","iPhone","iPod") ? 'ios' : 
    navigator.userAgent.includes("Android") ? 'android' : "other");    
  },[])

  useEffect(() => {
    onSnapshot(
        query(
            collection(firestore, 'ticket_scanners'),
            where('event', '==', id)
        ),
        (snap) => setScanner(snap.docs.map(e => ({ id: e.id, ...e.data() }))??[])
    )
  }, [id])

  const addScanner = () => {
    addDoc(
        collection(firestore, 'ticket_scanners'),
        {
            event: id,
            name: 'Neuer Ticketscanner',
        },
    );
  }

  function removeScanner(i) {
    deleteDoc(
        doc(
            collection(firestore, 'ticket_scanners'),
            scanner[i].id,
        ),
    );
  }

  function updateScannerName(index, name) {
    updateDoc(
        doc(collection(firestore, 'ticket_scanners'), scanner[index].id),
        { name },
    )
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

  const isFullWidth = windowWidth < 768;

  return (
    <Modal
        isOpen={show}
        appElement={el}
        unselectable="true"
        autoFocus={false}
        className="modalContentSmall"
        overlayClassName="modalOverlayNormal"
        shouldFocusAfterRender={false}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleClose}
        style={{
            content: {
                padding: isFullWidth ? "20px" : "25px 35px",
            },
        }}
    >
        <div className="mainBoxScan">
            <div className="headerBox">
                <div className="headerNameRow">
                    <div className="ModalHeader">Türsteher festlegen</div>
                    <div onClick={handleClose} className="cancelModal">{cancelIcon}</div>
                </div>
                <div className="headerSubheader">
                    Lege deine Liste von Leuten an, die Tickets scannen sollen. <br />
                    Schicke dir anschließend die 6-stelligen Codes, damit sie sich in der Scanapp anmelden können
                </div>
            </div>
            <div className='scannerContent'>
                <div className="main-content-modal">
                    <div className="title">Türsteherliste</div>
                    <div className="scanner-box">
                        {scanner.map((element,index) => {
                            return(
                                <div className="scanner-box-content"  key={element}>
                                    <div className="tile">
                                        <div className="tile-content">
                                            <div className="tile-left">
                                                <div className="scanner-name-title">Name</div>
                                                {scanner[index].loading ? <div style={{color: 'white'}}>Wird erstellt...</div> : <input type="text" className='scannerInput'
                                                    onChange={e => updateScannerName(index, e.target.value)} 
                                                    value={scanner[index].name}
                                                />}
                                            </div>
                                            <div className="code-box">
                                                <div className="code-title">Zugangscode</div>
                                                <div className="code">{scanner[index].code?.toUpperCase()??'Wird erstellt...'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="delete-box">
                                        <div className="delete-icon" onClick={() => removeScanner(index)}>{minusIcon}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="add-scanner-btn" onClick={() => {addScanner()}}>
                        <div>{plusIcon}</div>
                        <div>Person hinzufügen</div>
                    </div>
                </div>    
                {isFullWidth 
                ?   <div className='scannerQrBox' style={{padding: "20px"}}>
                        <div className='scannerQrTitle'>
                            <div> Klicke auf den Link, um dir die kostenlose "ELGIO Scanner" App runterzuladen </div>
                        </div>
                        <div className='w-full'>
                            {(os === "ios" || (os === "android")) && 
                                <a
                                    href={
                                    os === "android"
                                        ? "https://play.google.com/store/apps/details?id=de.elgio.ticketscanner&hl=en"
                                        : "https://apps.apple.com/de/app/elgio-scanner/id6464217997"
                                    }
                                    className="flex-gap-3rem Button bg-blue row-flex"
                                    style={{width: "calc(100% - 30px)"}}
                                >
                                    <div>{os === "android" ? "Zum Playstore" : "Zum Appstore"}</div>
                                </a>
                            }
                        </div>
                    </div>
                :   <div className='scannerQrBox'>
                        <div className='scannerQrTitle'>
                            <div> Scanne den QR-Code um dir die kostenlose "ELGIO Scanner" App runterzuladen oder suche danach im Appstore </div>
                        </div>
                        <img src={QRScanner} alt='qrScanapp' className='QrScanapp'/>
                    </div>
                }
            </div>
        </div>
    </Modal>)
}