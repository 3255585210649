/* eslint-disable react-hooks/exhaustive-deps */
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { firestore, storage } from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";
import "./account_erstellen.css";
import ArtistInput from "../../widgets/ArtistInput";
import DatePicker from "react-datepicker";
import "../../../index.scss";
import {
  change_color,
  change_color_icon_Nav,
  delete_image,
  delete_logo,
  preview_image_erstellen,
  preview_logo,
  rechange_color,
  rechange_color_icon_Nav,
} from "./account_erstellen_functions";
import {
  chevronDownIcon,
  chevronLeftIcon,
  cancelIcon,
  titelbildIcon,
  nameIcon,
  regionIcon,
  residentsIcon,
  beschreibungIcon,
  adresseIcon,
  kontaktdatenIcon,
  socialMediaIcon,
  deleteIcon,
  genreIcon,
  areasIcon,
  minusIcon,
  helpIcon,
  jobsIcon,
  plusIcon,
  openingHoursIcon,
  legalIcon,
} from "../../../icons";
import { toast } from "react-toastify";
import FurtherInfo from "../../widgets/tooltip";
import { infoToast } from "../../widgets/toasts.tsx";


export function Inputs(props) {
  const { organizerId } = useContext(UserContext);

  const [banner, setBanner] = useState("");
  const [icon, setIcon] = useState("");
  const [name, setName] = useState("");
  const [land, setLand] = useState("");
  const [region, setRegion] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [facebook, setFacebook] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [adress, setAdress] = useState("");
  const [plz, setPlz] = useState("");
  const [ort, setOrt] = useState("");
  const [shown, setShown] = useState(true);
  const [residents,setResidents] = useState([]);
  const [genre, setGenre] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [areas, setAreas] = useState([]);
  const [faq, setFaq] = useState([]);
  const [openingHours, setOpeningHours] = useState(Array(7).fill({}));
  const [company, setCompany] = useState([]);



  useEffect(() =>{
    setShown(true)
    document.getElementsByClassName('optionalBox')[0].style.display="flex"
  },[])

  function animateOptionalInp(){
    if (shown){
      setShown(false)
      document.getElementsByClassName('optionalBox')[0].className="optionalBox animate__animated animate__fadeOutUp"
      setTimeout(() => {
        document.getElementsByClassName('optionalBox')[0].style.display="none"
      },400)
    }
    else{
      setShown(true)
      document.getElementsByClassName('optionalBox')[0].className="optionalBox animate__animated animate__fadeInDown"
      setTimeout(() => {
        document.getElementsByClassName('optionalBox')[0].style.display="flex"
      },400)
    } 
  }

  useEffect(() => {
    organizerId && getDoc(doc(collection(firestore, "organizers"), organizerId))
      .then((snapshot) => {
        props.setAccountTyp(snapshot.data()?.type);
        setBanner(
          (snapshot.data().images?.length ?? 0) > 0
            ? snapshot.data().images[0]
            : null
        );
        setIcon(snapshot.data().icon);
        setName(snapshot.data().name);
        setDescription(snapshot.data().description);
        setWebsite(snapshot.data().website);
        setPhone(snapshot.data().phone);
        setEmail(snapshot.data().email);
        setResidents(snapshot.data().residents??[])
        setAdress(snapshot.data().address?.split(', ').length > 0 ? snapshot.data().address?.split(', ')[0] : '');
        setPlz(snapshot.data().address?.split(', ').length > 1 ? snapshot.data().address.split(', ')[1] : '');
        setOrt(snapshot.data().address?.split(', ').length > 2 ? snapshot.data().address.split(', ')[2] : '');
        setRegion(snapshot.data().city);
        setLand('Deutschland');
        setInstagram(snapshot.data().instagram);
        setGenre(snapshot.data().genre??[])
        setYoutube(snapshot.data().youtube);
        setTiktok(snapshot.data().tiktok);
        setFacebook(snapshot.data().facebook);
        setFaq(snapshot.data().frequently_asked_questions??[]);
        setJobs(snapshot.data().jobs??[]);
        setCompany(snapshot.data().company??[]);
        setOpeningHours(snapshot.data()?.opening_hours??Array(7).fill({}));
      })
      .catch((error) => console.log(error));
  }, [organizerId]);

  useEffect(() => {
    props.onDataChange({
      images: [banner],
      residents: residents,
      icon: icon ? icon : null,
      name: name,
      description: description,
      type: props.accountTyp,
      genre,
      address: adress && plz && ort ? adress + ', ' + plz + ', ' + ort : null,
      phone: phone ? phone : null,
      email: email ? email : null,
      website: website ? website : null,
      instagram: instagram ? instagram : null,
      youtube: youtube ? youtube : null,
      tiktok: tiktok ? tiktok : null,
      facebook: facebook ? facebook : null,
      city: region ? region : null,
      frequently_asked_questions: faq ? faq : null,
      jobs: jobs ? jobs : null,
      company: company ? company : null,
      opening_hours: openingHours,
    });
  }, [banner, icon, name, description, props.accountTyp, adress, plz, ort, phone, email, website, instagram, youtube, tiktok, facebook, region, residents, faq, genre, jobs, company, openingHours]);

  useEffect(() => {
    organizerId && getDocs(collection(firestore, 'organizers', organizerId, 'areas')).then(snap => setAreas(snap.docs.map(e => e.data())))
  }, [organizerId]);

  useEffect(() => {
    props.setAreas(areas);
  }, [areas]);

  //autoscroll from url (hash)
  useEffect(() => {
    setTimeout(() => {
      if (window.location.hash) {
        const element = document.getElementById(window.location.hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 1000);
  }, [window.location.hash]);

  return (
    <div className="ErstellenInputs">
      <div id="item">
        <NameInput name={name} setName={setName}></NameInput>
      </div>
      <div id="item">
        <AccountTypInput accountTyp={props.accountTyp} setAccountTyp={props.setAccountTyp}></AccountTypInput>
      </div>
      {["standard","label"].includes(props.accountTyp) && <div id="item">
        <LandUndRegionInput city={region} setCity={setRegion}></LandUndRegionInput>
      </div>}
      <div id="item">
        <TitelBildInput banner={banner} setBanner={setBanner}></TitelBildInput>
      </div>
      <div id="item">
        <BeschreibungInput description={description} setDescription={setDescription}></BeschreibungInput>
      </div>
      {!["label","standard"].includes(props.accountTyp) &&
       <div id="item">
        <AdresseInput adress={adress} setAdress={setAdress} plz={plz} setPlz={setPlz} ort={ort} setOrt={setOrt} land={land} setLand={setLand}></AdresseInput>
      </div>}
      <div>
        <div className="item" style={{marginBottom: "20px"}} onClick={() => {animateOptionalInp()}}>
          <div>
            <div className="optionalHead">Optionale Angaben</div>
            <div className="optionalSubHead">Genres, Icon, Social Media, Kontakt und Residents</div>
          </div>
          <div className="optionalIcon">{shown ? chevronDownIcon : chevronLeftIcon}</div>
        </div>
        <div className="optionalBox" style={{display:"flex",flexDirection:"column",gap:"20px"}}>
        <div id="item">
          <GenreInput genre={genre} setGenre={setGenre}></GenreInput>
        </div>
        <div id="item">
          <LogoInput icon={icon} setIcon={setIcon}></LogoInput>
        </div>
        <div id="item">
          <SocialMediaInput instagram={instagram} setInstagram={setInstagram} youtube={youtube} setYoutube={setYoutube} facebook={facebook} setFacebook={setFacebook} tiktok={tiktok} setTiktok={setTiktok}></SocialMediaInput>
        </div>
        <div id="item">
          <KontaktdatenInput website={website} setWebsite={setWebsite} email={email} setEmail={setEmail} phone={phone} setPhone={setPhone}></KontaktdatenInput>
        </div>
        <div id="item">
          <ResidentsInput residents={residents} setResidents={setResidents}></ResidentsInput>
        </div>
        {!["label","standard"].includes(props.accountTyp) && <div id="item">
          <OpeningHoursInput openingHours={openingHours} setOpeningHours={setOpeningHours}></OpeningHoursInput>
        </div>}
        {!["label","standard"].includes(props.accountTyp) && <div id="item">
          <AreasInput areas={areas} setAreas={setAreas}></AreasInput>
        </div>}
        <div id="item">
          <FaqInput faq={faq} setFaq={setFaq}></FaqInput>
        </div>
        <div id="item">
          <CompanyInput company={company} setCompany={setCompany}></CompanyInput>
        </div>
        <div id="item">
          <JobsInput jobs={jobs} setJobs={setJobs}></JobsInput>
        </div>
      </div>
    </div>
        {props.includeFooter && <Footer
          submit={props.submit}
          allDone={
            (name?.length??0) > 0
            && banner
            && (description?.length??0) > 0
            && props.accountTyp
            && (props.accountTyp !== 'venue' || (adress && plz && ort))
          }
        ></Footer>}
    </div>
  );
}

var TitelbildDone = false;
var allDone = false;

function AccountTypInput(props) {

  function checkArt(value) {
    if (!value) {
      rechange_color("art-icon-nav", "art-icon");
    } else {
      change_color("art-icon-nav", "art-icon");
    }
  }

  useEffect(() => {
    checkArt(props.accountTyp);
  }, [props.accountTyp]);

  return (
    <div className="InputBox" id="art">
      <div className="InputBoxHeader">
        <div className="InputCircle" id="art-icon">{socialMediaIcon}</div>
        <div className="InputBoxTitle">
          <div className="InputName">Accounttyp</div>
          <div className="InputDetails">Bitte wähle aus, was für eine Organisation du bist
          </div>
        </div>
      </div>
      <select
        id="accountTypSelect"
        className="TextInputSelect"
        onChange={(e) => {
          props.setAccountTyp(e.target.value);
          checkArt(e.target.value);
        }}
        value={props.accountTyp}
      >
        <option value="" style={{color: 'var(--lightgrey)'}}>Wähle einen Accounttypen aus:</option>
        <option value="venue">Club / Eventlocation</option>
        <option value="standard">Veranstalter</option>
        <option value="label">Label</option>
        <option value="cafe">Cafe</option>
        <option value="location">Location</option>
        <option value="bar">Bar</option>
      </select>
    </div>
  );
}

function TitelBildInput(props) {
  const { organizerId } = useContext(UserContext);

  function pressUploadBtn() {
    document.getElementById("bannerInput")?.click();
  }

  useEffect(() => {(async () => {
    if(!props.banner) return;
    document.getElementById("output_image").style.visibility='visible';
    document.getElementById('UploadBtnErstellen').style.visibility='hidden';
    change_color('titelbild-icon','titelbild-icon-nav');
    const downloadUrl = await getDownloadURL(ref(storage, props.banner));
    document.getElementById('output_image').src = downloadUrl;

    change_color('titelbild-icon','titelbild-icon-nav');
    
  })()}, [props.banner]);

  return (
    <div className="InputBox" id="titelbild">
      <div className="InputBoxHeader">
        <div className="InputCircle" id="titelbild-icon">{titelbildIcon}</div>
        <div className="InputBoxTitle">
          <div className="InputName">Titelbild</div>
          <div className="InputDetails">Dieses Bild wird auf deinem Profil angezeigt</div>
        </div>
      </div>
      <div className="col-flex gap-2">
        <div className="BildBorder" id="BildBorderAccount">
          <img id="output_image" alt=" " className="output_image_style"/>
          <button onClick={pressUploadBtn} title="Upload your Image" className="UploadBtnErstellen" id="UploadBtnErstellen">
            <div id="logoupload">Bild Auswählen</div>
            <input
              type={"file"}
              id="bannerInput"
              title="Titelbild"
              className="imgInp"
              accept="image/png ,image/jpeg, image/jpg"
              onChange={(evt, i) => {
                preview_image_erstellen(evt, i);
                TitelbildDone = true;
                const file = evt.target.files[0];
                const reference = ref(
                  storage,
                  "/organizer_images/" + organizerId + "/" + file.name
                );
                uploadBytes(reference, file)
                  .then(() => {
                    props.setBanner(reference.fullPath);
                  })
                  .catch((e) => console.log(e));
              }}
            />
          </button>
          <button
            id="delete"
            onClick={() => {
              if(!props.banner) return;
              delete_image();
              deleteObject(ref(storage,props.banner));
            }}
          >
            <div id="delete-icon-logo">{deleteIcon}</div>
          </button>
        </div>
        <div className="recoSize">Empfohlene Größe: 1350px x 1080px</div>
      </div>
    </div>
  );
}

function LogoInput(props) {

  const { organizerId } = useContext(UserContext);

  let downloadUrl
  
  // recolor and display of elements of icon
  useEffect(() => {
    if(props.icon){
      change_color('logo-icon','logo-icon-nav');    
    }
    else{
      rechange_color('logo-icon','logo-icon-nav')
    }
    (async () => {
      if(!props.icon){return} 
      document.getElementById('logoImg').style.display="block";
      document.getElementsByClassName('LogoUploadBtn')[0].style.display="none";
      downloadUrl = await getDownloadURL(ref(storage, props.icon));
      document.getElementById('logoImg').src = downloadUrl;

    }) ()
  }, [props.icon]);
  // recolor and display of elements of icon


  function pressUploadBtn() {
    document.getElementById("logoInputInp")?.click();
  }

  return (
    <div className="InputBox" id="logoInputDiv">
      <div className="InputBoxHeader">
        <div className="InputCircle" id="logo-icon">{titelbildIcon}</div>
        <div className="InputBoxTitle">
          <div className="InputName">Logo / Icon</div>
          <div className="InputDetails">Lade ein Logo oder Icon hoch</div>
        </div>
      </div>
      <div style={{display: "flex", flexDirection: "row"}}>
      <div className="LogoBorder">
        <img id="logoImg" alt=" " style={{ display: "none", objectFit: 'cover' }}></img>
        <button
          className="LogoUploadBtn UploadBtnErstellen"
          onClick={() => {
            pressUploadBtn();
          }}
          style={{ zIndex: "1" }}
        >
          <div id="logoupload">Bild Auswählen</div>
          <input
            type={"file"}
            accept="image/png ,image/jpeg, image/jpg"
            id="logoInputInp"
            style={{ display: "none" }}
            onChange={(evt, i) => {
              preview_logo(evt, i);
              const file = evt.target.files[0];
              const reference = ref(
                storage,
                "/organizer_images/" + organizerId + "/" + file.name
              );
              uploadBytes(reference, file)
                .then(() => {
                  props.setIcon(reference.fullPath);
                })
                .catch((e) => console.log(e));
            }}
          ></input>
        </button>
      </div>
      <div style={{marginRight: "auto", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginLeft: "auto", gap: "10px"}}>
        <button
          id="delete"
          style={{
            position: "unset",
          }}
          onClick={() => {
            delete_logo();
            rechange_color("logo-icon", "logo-icon-nav");
            checkAllInpDone();
          }}
        >
        <div id="delete-icon-logo">{deleteIcon}</div>
        </button>
        <div className="recoSize">Empfohlenes Verhältnis: 1 zu 1</div>
      </div>
      </div>
    </div>
  );
}

function NameInput(props) {

  useEffect(() => { props.name && checkName(props.name) }, [props.name])

  function checkName(value) {
    if (value.length < 3) {
      rechange_color("name-icon-nav", "name-icon");
    } else {
      change_color("name-icon-nav", "name-icon");
    }
  }

  return (
    <>
      <div className="InputBox" id="name">
        <div className="InputBoxHeader">
          <div className="InputCircle" id="name-icon">{nameIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName">Name</div>
            <div className="InputDetails">Wie lautet dein Veranstaltername?</div>
          </div>
        </div>
        <input
          type="text"
          id="nameInput"
          className="TextInput"
          placeholder="Gib hier den Namen an..."
          value={props.name??""}
          onChange={(e) => {
            props.setName(e.target.value);
            checkName(e.target.value);
          }}
          onBlur={() => {
            checkAllInpDone();
          }}
        />
      </div>
    </>
  );
}




function LandUndRegionInput(props) {
  const [cities, setCities] = useState([]);
  useEffect(() => { getDocs(collection(firestore, 'cities')).then(snap => setCities(snap.docs)) }, []);

  useEffect(() => {
    if(!["null",null].includes(props.city)) change_color("land-region-icon-nav", "land-region-icon");    
    else rechange_color("land-region-icon-nav", "land-region-icon");
  },[props.city])

  function check(value) {
    if(document.getElementById("land-region-icon-nav") != null){
      if (value !== "null") {
        change_color("land-region-icon-nav", "land-region-icon");
      } else {
        rechange_color("land-region-icon-nav", "land-region-icon");
      }
    }
    props.setCity(value)
  }


  return (
    <div className="InputBox" id="land-region">
      <div className="InputBoxHeader">
        <div className="InputCircle" id="land-region-icon">{regionIcon}</div>
        <div className="InputBoxTitle">
          <div className="InputName">Gebiet</div>
          <div className="InputDetails">Wo veranstaltest du den Hauptteil deiner Events?</div>
        </div>
      </div>
      <select className="TextInputSelect" onChange={(e) => check(e.target.value)} value={props.city}>
        <option value="null" style={{color: 'var(--lightgrey)'}}>Wähle eine Stadt aus:</option>
        {cities.filter(city => !city.data().hidden && !city.data().coming_soon).map(city => <option value={city.id}>{city.data().name}</option>)}
      </select>
    </div>
  );
}

function AdresseInput(props) {

  
  useEffect(() => {
    let index
    if(document.getElementById("land-region-icon-nav") === null){
      index = 4
    }
    else{
      index = 4
    }
    if(document.getElementById('adresse').style.display !== "none"){
      if (props.adress.length !== 0 && props.plz.length !== 0 && props.ort.length !== 0) {
        change_color_icon_Nav("circleSideBar", "InputCircle", index);
      } else {
        rechange_color_icon_Nav("circleSideBar", "InputCircle", index);
      }
    }
    else{
      rechange_color_icon_Nav("circleSideBar", "InputCircle", index);
    }
  }, [props.adress, props.plz, props.ort]);

  return (
    <div className="InputBox" id="adresse">
      <div className="InputBoxHeader">
        <div className="InputCircle" id="adresse-icon">{adresseIcon}</div>
        <div className="InputBoxTitle">
          <div className="InputName">Adresse</div>
          <div className="InputDetails">Hier finden dich deine Fans</div>
        </div>
      </div>
      <div className="row-input">
        <div className="col-input">
          <div className="input-title" id="StraUHau-text">
            Straße & Hausnummer
          </div>
          <input
            type="text"
            className="TextInput"
            id="straße_und_hausnummer"
            placeholder="z.B. Musterstraße 1"
            value={props.adress??""}
            onInput={(e) => {
              props.setAdress(e.target.value)
            }}
              onBlur={() => {
              const regex = /^(?=.*\b(?:straße|str|platz|pl|weg|allee|gasse)\b)(?=.*\b\d{1,5}(?:\s?\w)?\b).+/i;
              if(!regex.test(props.address)){
                // streetAndHousnumberToast()
              }
            }}
          />
        </div>
        <div className="col-input">
          <div className="input-title">
            Postleizahl
          </div>
          <input
            type="text"
            className="TextInput"
            id="plz"
            placeholder="z.B. 12345"
            style={{ position: "unset" }}
            value={props.plz??""}
            onInput={(e) =>{
              props.setPlz(e.target.value)
            }
            }
          />
        </div>
        <div className="col-input">
          <div className="input-title" id="Ort-text">
            Ort
          </div>
          <input
            type="text"
            className="TextInput"
            id="ort"
            style={{ position: "unset" }}
            placeholder="z.B. Musterstadt"
            value={props.ort??""}
            onInput={(e) => {
              props.setOrt(e.target.value)
            }}
          
          />
        </div>
        <div className="col-input">
          <div className="input-title">
            Land
          </div>
          <input
            disabled
            type="text"
            className="TextInput"
            id="land-kontakt"
            style={{ position: "unset" }}
            placeholder="z.B. Deutschland"
            value="Deutschland"
            onInput={(e) => {
              props.setLand(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

function ResidentsInput(props) {
  useEffect(() => {
      if (props.residents?.length > 0) {
          change_color("residentsNav", "residents");

      } else {
          rechange_color("residentsNav", "residents");
      }
    }, [props.residents]);

  const [idk, setIdk] = useState("");

  const [artistList, setArtistList] = useState([]);
  useEffect(() => { props.residents && Promise.all(Array.from(props.residents).map(e => e.startsWith('$') ? { id: e, data: () => ({ name: e.substring(1)})} : getDoc(doc(collection(firestore, 'artists'), e)))).then(e => setArtistList(e)) }, [props.residents]);

  return (
    <div className="InputBox" id="Residents">
      <div className="InputBoxHeader">
        <div className="InputCircle" id="residents">{residentsIcon}</div>
        <div className="InputBoxTitle">
          <div className="InputName">Residents</div>
          <div className="InputDetails">Das sind deine hauseigenen DJs</div>
        </div>
      </div>
      <ArtistInput
        key={props.residents}
        className="TextInput"
        placeholder="Artist hinzufügen..."
        onSelect={e => !props.residents.includes(e) && props.setResidents(props.residents.concat([e]))}
      ></ArtistInput>
      <div>
        <div id="ausgewaehlteGenres">Ausgewählte Künstler:</div>
        <div className="GenreBox">
          {props.residents?.map((item) => (
            <div
              id="GenreBoxItem"
              onClick={() => {
                var index = props.residents?.indexOf(item);
                if (index > -1) {
                  let tmp = props.residents;
                  tmp.splice(index, 1);
                  props.setResidents(tmp);
                  if(tmp.length === 0) rechange_color("residentsNav", "residents");
                  setIdk(!idk);
                }
              }}
              className="genreButton"
            >
              <div className="ItemText">{artistList.find(e => e.id === item)?.data()?.name}</div>
              <div>{cancelIcon}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function OpeningHoursInput(props) {
  useEffect(() => {
    //if not in settimeout the nav icon will not color properly on first open if opening times are already existant, idk why
    setTimeout(() => {
      if(props.openingHours.some(e => e.open)) {
        change_color('opening-hours-icon', 'opening-hours-icon-nav');
      } else {
        rechange_color('opening-hours-icon', 'opening-hours-icon-nav');
      }
    }, 50);
  }, [props.openingHours]);

  const DEFAULT_OPENING_TIME = '22:00';
  const DEFAULT_CLOSING_TIME = '06:00';

  const mostPopularOpeningTime = useMemo(() => {
    const occurences = props.openingHours.reduce((acc, day) => {
      if(!day.open) return acc;
      if (day.opening_time) {
        acc[day.opening_time] = (acc[day.opening_time] || 0) + 1;
      }
      return acc;
    }, {});
    return Object.keys(occurences).reduce((a, b) => occurences[a] > occurences[b] ? a : b, DEFAULT_OPENING_TIME);
  }, [props.openingHours]);
  const mostPopularClosingTime = useMemo(() => {
    const occurences = props.openingHours.reduce((acc, day) => {
      if(!day.open) return acc;
      if (day.closing_time) {
        acc[day.closing_time] = (acc[day.closing_time] || 0) + 1;
      }
      return acc;
    }, {});
    return Object.keys(occurences).reduce((a, b) => occurences[a] > occurences[b] ? a : b, DEFAULT_CLOSING_TIME);
  }, [props.openingHours]);

  const daysOfWeek = [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag',
  ];

  const handleClick = (index) => {
    props.setOpeningHours(props.openingHours.map((day, i) =>
      index === i ? {
        ...day,
        open: !day.open,
        opening_time: day.opening_time??mostPopularOpeningTime,
        closing_time: day.closing_time??mostPopularClosingTime,
       } : day
    ));
  };

  const handleTimeChange = (index, timeType, newTime) => {
    props.setOpeningHours(props.openingHours.map((day, i) =>
      index === i ? { ...day, [timeType]: `${newTime.getHours().toString().padStart(2, '0')}:${newTime.getMinutes().toString().padStart(2, '0')}` } : day
    ));
  };

  return (
    <div className="InputBox" id="opening-hours">
      <div className="InputBoxHeader">
        <div className="InputCircle" id="opening-hours-icon">{openingHoursIcon}</div>
        <div className="InputBoxTitle">
          <div className="InputName">Öffnungszeiten</div>
          <div className="InputDetails">Zeig deinen Gästen wann du üblich geöffnet hast</div>
        </div>
      </div>
      <div className="hoursBox">
        {props.openingHours.map((day, index) => (
          <div className="hoursBoxSingle" id={daysOfWeek[index]} key={daysOfWeek[index]}>
            <div className="hoursBoxLeft" onClick={() => handleClick(index)}>
              <div 
                className="hoursBoxSelect" 
                style={{ border: day.open ? '1px solid var(--blue)' : '1px solid var(--textSecondary)' }}
              >
                <div className="hoursBoxSelectMini" style={{ backgroundColor: day.open ? 'var(--blue)' : 'var(--bg2)' }}></div>
              </div>
              <div className="hoursBoxDay">{daysOfWeek[index]}</div>
            </div>
            <div className="hoursBoxRight">
              {day.open ? (
                <div className="hoursBoxTime">
                  <DatePicker
                    selected={day.opening_time ? new Date(0, 0, 0, parseInt(day.opening_time.split(':')[0]), parseInt(day.opening_time.split(':')[1])) : null}
                    onChange={(newTime) => handleTimeChange(index, 'opening_time', newTime)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Uhrzeit"
                    dateFormat="HH:mm"
                    className="timePicker"
                    enableTabLoop={false}
                    locale="de"
                  />
                  <div style={{ color: "var(--textMain)" }}>bis</div>
                  <DatePicker
                    selected={day.closing_time ? new Date(0, 0, 0, parseInt(day.closing_time.split(':')[0]), parseInt(day.closing_time.split(':')[1])) : null}
                    onChange={(newTime) => handleTimeChange(index, 'closing_time', newTime)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Uhrzeit"
                    dateFormat="HH:mm"
                    className="timePicker"
                    enableTabLoop={false}
                    locale="de"
                  />
                </div>
              ) : (
                <div className="closed">geschlossen</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function FaqInput(props) {
  useEffect(() => {
    if(props.faq.length > 0 && props.faq.every(e => e.question && e.answer)){
      change_color('faq-icon', 'faq-icon-nav');
    } else {
      rechange_color('faq-icon', 'faq-icon-nav');
    }
  }, [props.faq]);

  const addFaq =  (index,value) => {
    let tmp = props.faq
    tmp[index] = tmp[index] ? { ...tmp[index], ...value } : value
    props.setFaq(Array.from(tmp))
  }

  function removeFaq(i) {
    let tmp = props.faq;
    tmp.splice(i, 1);
    props.setFaq(Array.from(tmp));
  }
  
  return (
    <div className="InputBox" id="faq">
      <div className="InputBoxHeader">
        <div className="InputCircle" id="faq-icon">{helpIcon}</div>
        <div className="InputBoxTitle">
          <div className="InputName">FAQ</div>
          <div className="InputDetails">Stelle und beantworte häufig gestellte Fragen</div>
        </div>
      </div>
      <div className="multiEntryBox">
        {props.faq.map((element, index) =>
          <div className="row-flex flex-gap-1rem"> 
            <div className="entryBox">
              <div className="col-input">
                <div className="input-title">Frage</div>
                <input
                  className="InputErstellen"
                  placeholder="z.B. Wie kann ich bei euch bezahlen?"
                  value={props.faq[index].question}
                  onChange={(e) => addFaq(index,{ question: e.target.value })}
                ></input>
              </div>
              <div className="col-input">
                <div className="input-title">Antwort</div>
                <input
                  className="InputErstellen"
                  placeholder="Du kannst bei uns mit ..."
                  value={props.faq[index].answer}
                  onChange={(e) => addFaq(index,{ answer: e.target.value })}
                ></input>
              </div>
            </div>
            <div className="row-flex justify_center align_center">
              <div style={{color: 'var(--grey)', fontSize: "var(--fontsize-large)", cursor: "pointer"}} onClick={() => removeFaq(index)}>{minusIcon}</div>
            </div>
          </div>
        )}
        <div className="addElementMulti" onClick={() => {props.setFaq((prev) => [...prev,""])}}>
          {plusIcon}
          Eine Frage hinzufügen
        </div>
      </div>
    </div>
  );
}

export function CompanyInput(props) {
  const name = useMemo(() => props.company?.name ?? '', [props.company]);
  const setName = (value) => props.setCompany({ ...props.company, name: value });
  const representative = useMemo(() => props.company?.representative ?? '', [props.company]);
  const setRepresentative = (value) => props.setCompany({ ...props.company, representative: value });
  const registrationNumber = useMemo(() => props.company?.registrationNumber ?? '', [props.company]);
  const setRegistrationNumber = (value) => props.setCompany({ ...props.company, registrationNumber: value });
  const court = useMemo(() => props.company?.court ?? '', [props.company]);
  const setCourt = (value) => props.setCompany({ ...props.company, court: value });
  const line1 = useMemo(() => props.company?.address?.line1 ?? '', [props.company]);
  const setLine1 = (value) => props.setCompany({ ...props.company, address: { ...props.company?.address??{}, line1: value } });
  const postalCode = useMemo(() => props.company?.address?.postalCode ?? '', [props.company]);
  const setPostalCode = (value) => props.setCompany({ ...props.company, address: { ...props.company?.address??{}, postalCode: value } });
  const city = useMemo(() => props.company?.address?.city ?? '', [props.company]);
  const setCity = (value) => props.setCompany({ ...props.company, address: { ...props.company?.address??{}, city: value } });
  const country = useMemo(() => props.company?.address?.country ?? '', [props.company]);
  const setCountry = (value) => props.setCompany({ ...props.company, address: { ...props.company?.address??{}, country: value } });

  useEffect(() => {
    if(name && representative && registrationNumber && court && line1 && postalCode && city && country){
      change_color('legal-icon', 'legal-icon-nav');
    }
    else{
      rechange_color('legal-icon', 'legal-icon-nav');
    }
  }, [name, representative, registrationNumber, court, line1, postalCode, city, country]);
  
  return (
    <div className="InputBox" id="company">
      <div className="InputBoxHeader" style={{justifyContent: "space-between"}}>
        <div className="InputBoxHeaderLeft">
          <div className="InputCircle" id="legal-icon">{legalIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName">Firmendaten</div>
            <div className="InputDetails">Trage hier Infos zu deiner Firma ein</div>
          </div>
        </div>
        <FurtherInfo 
            explanation="Wir nutzen diese um ein korrektes Impressum und korrekte Datenschutzrichtlinien für deine Website zu generieren. Diese Daten tauchen sonst nirgends auf."
          />
      </div>
        <div className="row-input">
          <div className="col-input">
            <div className="input-title" id="legal-name-text">Unternehmensname</div>
            <input
              type="text"
              className="TextInput"
              id="legal-name"
              style={{ position: "unset" }}
              placeholder="z.B. XYZ GmbH"
              value={name}
              onInput={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="col-input">
            <div className="input-title" id="representative-text">Vertreter</div>
            <input
              type="text"
              className="TextInput"
              id="representative"
              placeholder="z.B. Max Mustermann"
              style={{ position: "unset" }}
              value={representative}
              onInput={(e) =>{
                setRepresentative(e.target.value)
              }
              }
            />
          </div>
          <div className="col-input">
            <div className="input-title" id="register-id-text">Handelsregisternummer</div>
            <input
              type="text"
              className="TextInput"
              id="register-id"
              style={{ position: "unset" }}
              placeholder="z.B. HRB 123456"
              value={registrationNumber}
              onInput={(e) => {
                setRegistrationNumber(e.target.value)
              }}
            />
          </div>
          <div className="col-input">
            <div className="input-title" id="court-text">Amtsgericht</div>
            <input
              type="text"
              className="TextInput"
              id="court"
              style={{ position: "unset" }}
              placeholder="z.B. Köln Innenstadt"
              value={court}
              onInput={(e) => {
                setCourt(e.target.value);
              }}
            />
          </div>
          <div className="col-input">
            <div className="input-title" id="legal-street-text">Straße & Hausnummer</div>
            <input
              type="text"
              className="TextInput"
              id="legal-straße_und_hausnummer"
              placeholder="z.B. Musterstraße 1"
              value={line1}
              onInput={(e) => {
                setLine1(e.target.value)
              }}
                onBlur={() => {
                const regex = /^(?=.*\b(?:straße|str|platz|pl|weg|allee|gasse)\b)(?=.*\b\d{1,5}(?:\s?\w)?\b).+/i;
                if(!regex.test(props.address)){
                  // streetAndHousnumberToast()
                }
              }}
            />
          </div>
          <div className="col-input">
            <div className="input-title" id="legal-plz-text">Postleitzahl</div>
            <input
              type="text"
              className="TextInput"
              id="legal-plz"
              style={{ position: "unset" }}
              placeholder="z.B. 52313"
              value={postalCode}
              onInput={(e) => {
                setPostalCode(e.target.value)
              }}
            />
          </div>
          <div className="col-input">
            <div className="input-title" id="legal-city-text">Stadt</div>
            <input
              type="text"
              className="TextInput"
              id="legal-city"
              style={{ position: "unset" }}
              placeholder="z.B. Musterstadt"
              value={city}
              onInput={(e) => {
                setCity(e.target.value)
              }}
            />
          </div>
          <div className="col-input">
            <div className="input-title" id="legal-land-text">Land</div>
            <input
              type="text"
              className="TextInput"
              id="legal-land"
              style={{ position: "unset" }}
              placeholder="z.B. Deutschland"
              value={country}
              onInput={(e) => {
                setCountry(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
  );
}

function AreasInput(props) {
  const { organizerId } = useContext(UserContext);

  function pressUploadBtn(index) {
    document.getElementById(`area-file-input-${index}`)?.click();
  }

  const addArea =  (index,value) => {
    let tmp = props.areas
    tmp[index] = tmp[index] ? { ...tmp[index], ...value } : value
    props.setAreas(Array.from(tmp))
  }

  function removeArea(i) {
    let tmp = props.areas;
    tmp.splice(i, 1);
    props.setAreas(Array.from(tmp));
  }

  const [images, setImages] = useState(null);
  useEffect(() => {
    if(images) return;
    if(!props.areas.length) return;
    Promise.all(props.areas.map(area => area.images?.length ? getDownloadURL(ref(storage, area.images[0])) : null)).then(setImages);
  }, [props.areas]);

  useEffect(() => {
    //if not in settimeout the nav icon will not color properly on first open if areas are already existant
    setTimeout(() => {
      if(props.areas.length > 0 && props.areas.every(e => e.name && e.description)){
        change_color('areas-icon','areas-icon-nav');
      } else {
        rechange_color('areas-icon','areas-icon-nav');
      }
    });
  }, [props.areas]);
  
  return (
    <div className="InputBox" id="areas">
      <div className="InputBoxHeader">
        <div className="InputCircle" id="areas-icon">{areasIcon}</div>
        <div className="InputBoxTitle">
          <div className="InputName">Areas</div>
          <div className="InputDetails">Stelle deine verschiedenen Floors vor</div>
        </div>
      </div>
      <div className="multiEntryBox">
        {props.areas.map((element, index) =>
          <div style={{display: "flex", gap: "10px"}} key={"areas_key_" + index}>
            <div className="entryBox">
              <div className="col-input">
                <div className="input-title">Bild</div>
                <div className="BildBorder" id="BildBorderAccount">
                  <img src={images ? images[index] : null} alt=" " className="output_image_style" style={{visibility: images && images[index] ? 'visible' : 'hidden'}} />
                  <button onClick={() => pressUploadBtn(index)} title="Upload your Image" className="UploadBtnErstellen">
                    <div id="logoupload">Bild Auswählen</div>
                    <input
                      type="file"
                      id={`area-file-input-${index}`}
                      className="imgInp"
                      accept="image/png ,image/jpeg, image/jpg"
                      onChange={(evt, i) => {
                        const file = evt.target.files[0];
                        const reference = ref(
                          storage,
                          "/organizers/" + organizerId + "/areas/" + file.name
                        );
                        setImages((prev) => {
                          let tmp = [...prev];
                          tmp[index] = URL.createObjectURL(file);
                          return tmp;
                        });
                        uploadBytes(reference, file)
                          .then(() => {
                            addArea(index,{ images: [reference.fullPath] })
                          })
                      }}
                    />
                  </button>
                </div>
              </div>
              <div className="col-input">
                <div className="input-title">Name</div>
                <input
                  className="InputErstellen"
                  placeholder="z.B. Mainfloor."
                  value={element.name}
                  onChange={(e) => addArea(index,{ name: e.target.value })}
                ></input>
              </div>
              <div className="col-input">
                <div className="input-title">Beschreibung</div>
                <input
                  className="InputErstellen"
                  placeholder="z.B. Hier findet ..."
                  value={element.description}
                  onChange={(e) => addArea(index,{ description: e.target.value })}
                ></input>
              </div>
            </div>
            <div className="row-flex justify_center align_center">
              <div style={{color: 'var(--grey)', fontSize: "var(--fontsize-large)", cursor: "pointer"}} onClick={() => removeArea(index)}>{minusIcon}</div>
            </div>
          </div>
        )}
        <div className="addElementMulti" onClick={() => {props.setAreas((prev) => [...prev,{}])}}>
          {plusIcon}
          Eine Area hinzufügen
        </div>
      </div>
    </div>
  );
}

function JobsInput(props) {
  useEffect(() => {
    if(props.jobs.length > 0 && props.jobs.every(e => e.title && e.description)){
      change_color('jobs-icon','jobs-icon-nav');
    } else {
      rechange_color('jobs-icon','jobs-icon-nav');
    }
  }, [props.jobs]);

  const addJob =  (index,value) => {
    let tmp = props.jobs
    tmp[index] = tmp[index] ? { ...tmp[index], ...value } : value
    props.setJobs(Array.from(tmp))
  }

    function removeJob(i) {
    let tmp = props.jobs;
    tmp.splice(i, 1);
    props.setJobs(Array.from(tmp));
  }
  
  return (
    <div className="InputBox" id="jobs">
      <div className="InputBoxHeader">
        <div className="InputCircle" id="jobs-icon">{jobsIcon}</div>
        <div className="InputBoxTitle">
          <div className="InputName">Jobs</div>
          <div className="InputDetails">Stelle deine aktuellen Stellenanzeigen vor</div>
        </div>
      </div>
      <div className="multiEntryBox">
        {props.jobs.map((element, index) =>
          <div className="row-flex flex-gap-1rem"> 
            <div className="entryBox">
              <div className="col-input">
                <div className="input-title">Jobtitel</div>
                <input
                  className="InputErstellen"
                  placeholder="z.B. Barkeeper"
                  value={props.jobs[index].title}
                  onChange={(e) => addJob(index,{ title: e.target.value })}
                ></input>
              </div>
              <div className="col-input">
                <div className="input-title">Jobbeschreibung</div>
                <input
                  className="InputErstellen"
                  placeholder="z.B. Wir suchen eine nette ..."
                  value={props.jobs[index].description}
                  onChange={(e) => addJob(index,{ description: e.target.value })}
                ></input>
              </div>
            </div>
            <div className="row-flex justify_center align_center">
              <div style={{color: 'var(--grey)', fontSize: "var(--fontsize-large)", cursor: "pointer"}} onClick={() => removeJob(index)}>{minusIcon}</div>
            </div>
          </div>
        )}
        <div className="addElementMulti" onClick={() => {props.setJobs((prev) => [...prev,""])}}>
          {plusIcon}
          Einen Job hinzufügen
        </div>
      </div>
    </div>
  );
}


function SocialMediaInput(props) {

  useEffect(() => checkSM(), [props.instagram, props.youtube, props.tiktok, props.facebook])

  function checkSM() {
    if ((props.instagram || props.youtube || props.tiktok || props.facebook || '').length === 0) {
      rechange_color("social-media-icon-nav", "social-media-icon");
    } else {
      change_color("social-media-icon-nav", "social-media-icon");
    }
  }

  function checkForLink() {
    //Instagram
    const instaRegex = /(?:(?:(?:https?:\/\/)?(?:www\.)?instagram\.com\/)|@)([a-zA-Z0-9_]+)/;
    const instaMatch = props.instagram?.match(instaRegex);
    if(instaMatch) props.setInstagram(instaMatch[1]);

    //YouTube
    const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:c\/|channel\/|user\/|@))?([a-zA-Z0-9_-]{1,})/;
    const youtubeMatch = props.youtube?.match(youtubeRegex);
    if(youtubeMatch) props.setYoutube(youtubeMatch[1]);

    //TikTok
    const tiktokRegex = /(?:(?:https?:\/\/)?(?:www\.)?tiktok\.com\/)?@([a-zA-Z0-9_.-]+)/;
    const tiktokMatch = props.tiktok?.match(tiktokRegex);
    if(tiktokMatch) props.setTiktok(tiktokMatch[1]);

    //Facebook
    const facebookRegex = /(?:https?:\/\/)?(?:www\.)?facebook\.com\/([a-zA-Z0-9.-]+)\/?$/;
    const facebookMatch = props.facebook?.match(facebookRegex);
    if(facebookMatch) props.setFacebook(facebookMatch[1]);
  }

  return (
    <div className="InputBox" id="social-media">
      <div className="InputBoxHeader">
        <div className="InputCircle" id="social-media-icon">{socialMediaIcon}</div>
        <div className="InputBoxTitle">
          <div className="InputName">Social Media</div>
          <div className="InputDetails">Hier kannst du gerne deine Social Media Kanäle verlinken</div>
        </div>
      </div>
      <div className="row-input">
        <div className="col-input">
          <div className="input-title">
            Instagram
           </div>
          <input
            type="text"
            placeholder="z.B. elgioapp"
            className="TextInput"
            id="insta-inp"
            value={props.instagram??""}
            onInput={(e) => { props.setInstagram(e.target.value); checkSM(e.target.value) }}
            onBlur={checkForLink}
          />
        </div>
        <div className="col-input">
          <div className="input-title">
            Youtube
          </div>
          <input
              type={"text"}
              placeholder="z.B. https://youtube.de/@elgioapp"
              className="TextInput"
              id="yt-inp"
              value={props.youtube??""}
              onInput={(e) => {
                props.setYoutube(e.target.value)
                checkSM(e.target.value)
              }}
              onBlur={checkForLink}
            />
        </div>
        <div className="col-input">
          <div className="input-title">
            TikTok
           </div>
          <input
            type={"text"}
            placeholder="z.B. elgioapp"
            className="TextInput"
            id="tiktok-inp"
            value={props.tiktok}
            onInput={(e) => {
              props.setTiktok(e.target.value)
              checkSM(e.target.value)
            }}
            onBlur={checkForLink}
          />
        </div>
        <div className="col-input">
          <div className="input-title">
            Facebook
          </div>
          <input
              type={"text"}
              placeholder="z.B. https://www.facebook.com/ElgioClub"
              className="TextInput"
              id="fb-inp"
              value={props.facebook}
              onInput={(e) => {
                props.setFacebook(e.target.value)
                checkSM(e.target.value)
              }}
              onBlur={checkForLink}
            />
        </div>
      </div>
    </div>
  );
}

function BeschreibungInput(props) {
  const [BeschreibungInputVal] = useState("");
  const [length, setLength] = useState(350);
  useEffect(() => { props.description && check(
    props.description,
    "beschreibung-icon-nav",
    "beschreibung-icon"
  ) }, [props.description])
  return (
    <div className="InputBox" id="description">
      <div className="InputBoxHeader">
        <div className="InputCircle" id="beschreibung-icon">{beschreibungIcon}</div>
        <div className="InputBoxTitle">
          <div className="InputName">Beschreibung</div>
          <div className="InputDetails">Schreiben Interessenten, was sie erwarten wird</div>
        </div>
      </div>
      <textarea
        name="Beschreibung"
        cols="2"
        rows="2"
        placeholder="z.B. 90er Party mit Freibier bis 22 Uhr und weiteren Specials"
        className="TextInputBeschreibung"
        maxLength={350}
        value={props.description}
        onInput={(e) => {
          props.setDescription(e.target.value);
          check(
            e.target.value,
            "beschreibung-icon-nav",
            "beschreibung-icon"
          );
        }}
        onKeyDown={onKeyDown}
        onBlur={() => {
          setLengthtoDefault();
        }}
      ></textarea>
      <div id="maxLength" className="input-title" style={{position: "absolute"}}>
        Max. Chars: <br /> {length}
      </div>
    </div>
  );

  function onKeyDown(e) {
    if (e.keyCode === 8 && !BeschreibungInputVal === "") {
      setLength(length + 1);
    } else if (!BeschreibungInputVal.length === -1) {
      setLength(length - 1);
    } else if (length >= 350) {
      setLength(350);
    } else if (length <= 0) {
      alert("Du hast keine Zeichen mehr frei!");
      setLength(0);
    }
  }
  function setLengthtoDefault() {
    if (BeschreibungInputVal === "") {
      setLength(350);
    }
  }
  function check(inp, idnav, idfield) {
    if (inp === "") {
      rechange_color(idnav, idfield);
    } else {
      change_color(idnav, idfield);
    }
  }
}

function KontaktdatenInput(props) {
  useEffect(check, [props.website, props.email, props.phone]);
  const urlNotValidToast = () => toast.info("Gib eine richtige Website ein!")
  const phoneNotValidToast = () => toast.info("Gib eine richtige Telefonnummer ein!")
  const emailNotValidToast = () => toast.info("Gib eine richtige Email ein!")

  return (
    <div className="InputBox" id="kontaktdaten">
      <div className="InputBoxHeader">
        <div className="InputCircle" id="kontaktdaten-icon">{kontaktdatenIcon}</div>
        <div className="InputBoxTitle">
          <div className="InputName">Kontaktdaten</div>
          <div className="InputDetails">Hier können dich deine Follower erreichen</div>
        </div>
      </div>
      <div className="row-input">
          <div className="col-input">
            <div className="input-title">
              Website
            </div>
            <input
              type="text"
              placeholder="z.B. https://elgio.de"
              className="TextInput"
              id="website-inp"
              value={props.website}
              onInput={(e) => props.setWebsite(e.target.value)}
              onBlur={() => {
                check();
                validateURL()
                checkAllInpDone();
              }}

            />
          </div>
          <div className="col-input">
            <div className="input-title">
              Email
            </div>
                  <input
              type={"text"}
              placeholder="z.B. info@elgio.de"
              id="email-inp"
              className="TextInput"
              value={props.email}
              onInput={(e) => props.setEmail(e.target.value)}
              onBlur={() => {
                check();
                validateEmail()
                checkAllInpDone();
              }}
            />
          </div>
          <div className="col-input">
            <div className="input-title">
              Telefon
            </div>
            <input
              type="tel"
              placeholder="z.B. +49189823938"
              className="TextInput"
              id="tel-inp"
              value={props.phone}
              onInput={(e) => props.setPhone(e.target.value)}
              onBlur={() => {
                validatePhone(props.phone);
                check();
                checkAllInpDone();
              }}
            />
          </div>
          <div className="col-input">
            <div className="input-title" style={{visibility:"hidden"}}></div>
            <input className="inp" style={{position:"relative",top:"0",left:"0",visibility:"hidden",width:"10.9375vw"}}/>
          </div>
      </div>     
    </div>
  );

  function validatePhone() {
    var regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    if (!regex.test(props.phone) || props.phone.length <= 11) {
      phoneNotValidToast()
    }
  }
  function validateURL(){
    var regex= /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
    if(!regex.test(props.website)){
      urlNotValidToast()
    }
  }
  function validateEmail(){
    var regex = /^(?:[\w!#$%&'*+\-/=?^`{|}~]+\.)*[\w!#$%&'*+\-/=?^`{|}~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/
    if(!regex.test(props.email)){
      emailNotValidToast()
    }
  }
  function check() {
    if (
      !props.website &&
      !props.email &&
      !props.phone
    ) {
      rechange_color("kontaktdaten-icon-nav", "kontaktdaten-icon");
    } else {
      change_color("kontaktdaten-icon-nav", "kontaktdaten-icon");
    }
  }
}

function GenreInput(props) {
  useEffect(() => {
    if(props.genre?.length > 0) {
      change_color_icon_Nav("circleSideBar", "InputCircle", 5);
    } else {
      rechange_color_icon_Nav("circleSideBar", "InputCircle", 5);
    }
  }, [props.genre]);

  const [genreList, setGenreList] = useState([]);
  const [idk, setIdk] = useState("");

  useEffect(() => { getDocs(collection(firestore, 'genres')).then(e => setGenreList(e.docs)) }, []);


  return (
    <div className="InputBox" id="genreDiv">
      <div className="InputBoxHeader">
        <div className="InputCircle">{genreIcon}</div>
        <div className="InputBoxTitle">
          <div className="InputName">Musikgenre</div>
          <div className="InputDetails">Welche Genre laufen typischerweise bei dir</div>
        </div>
      </div>
        <select className="TextInputSelect" onChange={(e) => {!props.genre.includes(e.currentTarget.value) && props.setGenre(props.genre.concat([e.currentTarget.value])); e.currentTarget.value = 'null'}}>
          <option value="null">Bitte auswählen</option>
          {genreList.map(e => <option value={e.id} key={e.id}>
            {e.data().de}
          </option>)}
        </select>
      <div>
        <div id="ausgewaehlteGenres">Ausgewählte Genre:</div>
        <div className="GenreBox">
          {props.genre.map((item, index) => (
            <div
              key={"genre_key_" + index}
              id="GenreBoxItem"
              onClick={() => {
                var index = props.genre?.indexOf(item);
                if (index > -1) {
                  let tmp = props.genre;
                  tmp.splice(index, 1);
                  props.setGenre(tmp);
                  if(tmp.length === 0) rechange_color_icon_Nav("circleSideBar", "InputCircle", 5);
                  setIdk(!idk);
                }
              }}
              className="genreButton"
            >
              <div className="ItemText">{genreList.find(e => e.id === item)?.data().de}</div>
              <div>{cancelIcon}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function Footer({ submit, allDone }) {
  function check(e) {
    if (allDone === false) {
      e.preventDefault();
      infoToast("Du musst alle Angaben ausfuellen um dein Profil zu erstellen!");
    } else {
      //document.getElementById('erstellenBtn')?.click();
      submit();
    }
  }

  return (
    <div className="footer">
      <div className="col-flex">
        <div style={{ color: allDone ? "var(--white)" : "var(--lightgrey)", fontSize: "var(--fontsize-medium)", textAlign: "center" }}>
          {allDone ? "Perfekt, du hast alle Angaben gemacht!" : "Es fehlen noch Angaben. Fülle alle Pflichtfelder aus um dein Profil zu erstellen!"}
        </div>
      </div>
      <div className="col-flex">
        <div className="erstellenBtn" style={allDone ? { backgroundColor: "var(--bgBlue)" } : { backgroundColor: "var(--bg4)", color: "var(--textSecondary)" }} onClick={(e) => check(e)}>Erstellen</div>
        <div className="header-disclaimer">Durch das Klicken auf "Erstellen" stimmst du den&nbsp;
          <a href={"https://www.elgio.de/terms-of-service-dashboard"} target="_blank" rel="noopener noreferrer" id="AgbLink" disabled>AGBs</a>
          &nbsp;von ELGIO zu.
        </div>
      </div>
    </div>
  );
}

function VorschauContainer(props) {
  return (
    <div className="Vorschau" style={{ left: "62vw", display: "none" }}>
      <div id="vorschauheader">
        Appvorschau
      </div>
      <div className= "Beschreibungsuntertitel">
        So sehen deine Gäste dein Event in der App
      </div>
      {/* <iframe
        src={`https://elgio-apppreview.web.app/organizer?name=${props.name}&description=${props.description}&phone=${props.tele}&website=${props.website}&address=${props.adress}&email=${props.email}&banner=${props.banner}&icon=${props.icon}`}
        className="VorschauIFrame"
      ></iframe> */}
    </div>
  );
}



function checkAllInpDone() {}

export {
  TitelBildInput,
  NameInput,
  LandUndRegionInput,
  SocialMediaInput,
  BeschreibungInput,
  KontaktdatenInput,
  VorschauContainer,
  TitelbildDone,
  allDone,
};