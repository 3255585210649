import { Route, Routes } from "react-router-dom";
import { EventEinzeln } from "./components/pages/events/event-einzeln";
import EventErstellen from "./components/pages/events/event-erstellen";
import MeineEvents from "./components/pages/events/events";
import Home from "./components/pages/home";
import Marketing from "./components/pages/marketing";
import Network from "./components/pages/network";
import { MeinProfil } from "./components/pages/profil";
import { MeinProfilBearbeiten } from "./components/pages/profil_bearbeiten";
import Fusion from "./components/pages/special/fusion";
import Statistics from "./components/pages/statistics";
import DiscountCodes from "./components/pages/ticketing/discount-codes";
import Ticketing from "./components/pages/ticketing/ticketing";
import TicketingAnlegen from "./components/pages/ticketing/ticketing_anlegen";
import Ticketoverview from "./components/pages/ticketing/ticketoverview";
import { SideNav } from "./components/widgets/sidenavs";
import TopBar from "./components/widgets/topBar";
import NotFound from "./components/pages/special/404";
import Website from "./components/pages/website";
import WebsiteBearbeiten from "./components/pages/website_bearbeiten";
import { isTestUser } from "./App";
import { useState } from "react";
import { Helmet } from "react-helmet";

export default function Dashboard() {
    const [showSideNav, setShowSideNav] = useState(false);
    
    return <div>
        <div style={{display: "flex"}}>
            <SideNav showSideNav={showSideNav}/>
            <div className="col-flex" id="containerDashboard">
                <TopBar setShowSideNav={setShowSideNav}/>
                <Routes>
                    <Route path="" element={
                    <>
                        <Helmet>
                            <title>ELGIO-Dashboard Home </title>
                        </Helmet>
                
                        <Home />
                    </>
                    }/>
                    <Route path="profil" element={
                        <>
                            <Helmet>
                                <title>ELGIO-Dashboard Profil</title>
                            </Helmet>
                            <MeinProfil />
                        </>
                    }/>
                    <Route path="profil/bearbeiten" element={
                    <>
                        <Helmet>
                            <title>ELGIO-Dashboard Profil bearbeiten</title>
                        </Helmet>
                        <MeinProfilBearbeiten/>
                    </>
                    }/>
                    <Route path="events" element={
                    <>
                        <Helmet>
                            <title>ELGIO-Dashboard Events</title>
                        </Helmet>
                        <MeineEvents/>
                    </>
                    }/>
                    <Route path="events/event/:id" element={
                    <>	
                        <Helmet>
                            <title>ELGIO-Dashboard Event-Einzeln </title>
                        </Helmet>
                        <EventEinzeln/>
                    </>
                    }/>
                    <Route path="events/ticket-overview/:id" element={
                    <>  
                        <Helmet>
                            <title>ELGIO-Dashboard Ticket-Übersicht</title>
                        </Helmet>
                        <Ticketoverview/>
                    </>
                    }/>
                    <Route path="events/event-erstellen" element={
                    <>
                        <Helmet>
                            <title>ELGIO-Dashboard Event Erstellen</title>
                        </Helmet>
                        <EventErstellen/>
                    </>
                    }/>
                    {isTestUser 
                        ? <Route path="discount-codes" element={
                        <>
                            <Helmet>
                                <title>ELGIO-Dashboard Discount Codes</title>
                            </Helmet>
                            <DiscountCodes/>
                        </>
                    }/>
                        : <> </>
                    }
                    <Route path="events/event-bearbeiten/:id" element={
                    <>
                        <Helmet>
                            <title>ELGIO-Dashboard Event Bearbeiten</title>
                        </Helmet>  
                        <EventErstellen/>
                    </>
                    }/>
                    <Route path="events/ticketing/:id" element={
                    <>
                        <Helmet>
                            <title>ELGIO-Dashboard Ticketing</title>
                        </Helmet>
                        <TicketingAnlegen/>
                    </>
                    }/>
                    <Route path="ticketing"element={
                    <>
                        <Helmet>
                            <title>ELGIO-Dashboard Ticketing</title>
                        </Helmet>
                        <Ticketing/>
                    </>
                    }/>
                    <Route path="ticketing/anlegen/:id" element={
                    <>
                        <Helmet>
                            <title>ELGIO-Dashboard Ticketing Anlegen</title>
                        </Helmet>
                        <TicketingAnlegen/>
                    </>
                    }/>
                    <Route path="statistics" element={
                    <>
                        <Helmet>
                            <title>ELGIO-Dashboard Statistiken</title>
                        </Helmet>
                        <Statistics/>
                    </>
                    }/>
                    <Route path="marketing" element={
                    <>
                        <Helmet>
                            <title>ELGIO-Dashboard Marketing</title>
                        </Helmet>
                        <Marketing/>
                    </>
                    }/>
                    <Route path="website" element={
                    <>
                        <Helmet>
                            <title>ELGIO-Dashboard Website</title>
                        </Helmet>                
                        <Website/>
                    </>
                    }/>
                    <Route path="website/website-bearbeiten" element={
                    <>
                        <Helmet>
                            <title>ELGIO-Dashboard Website bearbeiten</title>
                        </Helmet>
                        <WebsiteBearbeiten/>
                    </>
                    }/>
                    <Route path="fusion-info" element={
                    <>
                        <Helmet>
                            <title>ELGIO-Dashboard Dein Profil wurde Fusioniert!</title>
                        </Helmet>
                        <Fusion/>
                    </>
                    }/>
                    <Route path="network" element={
                    <>
                        <Helmet>
                            <title>ELGIO-Dashboard Netzwerk</title>
                        </Helmet>
                        <Network/>
                    </>
                    }/>
                    <Route path="*" element={<NotFound></NotFound>}></Route>
                </Routes>
            </div>
        </div>
    </div>
}