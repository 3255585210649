import { addDoc, collection, deleteDoc, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore'
import React, { useContext, useState } from 'react'
import "../../../index.scss";
import { useEffect } from 'react'
import { useRef } from 'react'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'
import { firestore } from '../../../firebase'
import Papa from 'papaparse'
import { UserContext } from "../../../providers/UserProvider";
import Template from "../../../graphics/CSV-Vorlage.png"
import "./gaesteliste.css";
import { cancelIcon, chevronDownIcon, deleteIcon, plusIcon, searchIcon } from '../../../icons';
import FurtherInfo from '../../widgets/tooltip';

function Gaesteliste({show,handleClose}) {
  const el = Modal.setAppElement('#portal')
  const el2 = Modal.setAppElement('#portal2')

  const [totalNumber,setTotalNumber] = useState(35)

  const {id} = useParams();
  const [searchQuery, setSearchQuery] = useState('');
  const {organizerId, permissions, user} = useContext(UserContext)

  const [gaesteliste,setGaesteliste] = useState([]);
  const [name,setName] = useState("");
  const [anmerkung,setAnmerkung] = useState("");
  const [email, setEmail] = useState("");

  const tableRef = useRef(null);
  const [showTemplate,setShowTemplate] = useState(false)

  const handleCloseTemplate = () => {
    setShowTemplate(false)
  }

  const [showAddBtn, setShowAddBtn] = useState(false);

  const [moreInfosOpen, setMoreInfosOpen] = useState(null);
  
  const [activeList,setActiveList] = useState('guestlist');
  const [addList, setAddList] = useState('');
  
  const [scanner,setScanner] = useState([]);
  useEffect(() => {
    onSnapshot(
        query(
            collection(firestore, 'ticket_scanner'),
            where('event', '==', id)
        ),
        (snap) => setScanner(snap.docs.map(e => ({ id: e.id, ...e.data() }))??[])
    )
  }, [id]);
  const [teamMembers, setTeamMembers] = useState(null);
  useEffect(() => {
    getDoc(doc(firestore, 'organizers', organizerId)).then(snap => {
      Promise.all(snap.data().users.map(userId => getDoc(doc(firestore, 'users', userId)))).then(users => {
        setTeamMembers(users.map(user => ({id: user.id, ...user.data()})));
      });
    });
  }, [organizerId]);

  useEffect(() => {
    setTotalNumber(gaesteliste.length)
  },[gaesteliste])

  useEffect(() => {
    onSnapshot(
      query(
        collection(firestore, 'tickets'),
        where('event', '==', id),
        where('type', "==", "guestlist")
       
      ),
      (snap) => setGaesteliste(snap.docs.map(e => ({
        id: e.id,
        name: e.data().first_name,
        liste: (() => {
          switch(e.data().list){
            case 'guestlist': return 'Gästeliste'
            case 'friendslist': return 'Freundesliste'
            default: return e.data().list
          }
        })(),

        addedBy: e.data().created_by === organizerId
          ? 'Hauptkonto'
          : [...(scanner??[]), ...(teamMembers??[])].find(f => f.id === e.data().created_by)?.name ?? e.data().created_by,

        addedVia: (() => {
          switch(e.data().created_via) {
            case 'dashboard': return 'Dashboard';
            case 'ticketscanner': return 'Scan-App';
            case 'import': return 'Import';
            default: return e.data().created_via;
          }
        })(),
        ...e.data(),
      }))),
    )
  }, [id, organizerId, scanner, teamMembers])


  const addGast = ({name,anmerkung,email,addedVia}) => {
    addDoc(
      collection(firestore, 'tickets'),
      {
        first_name: name,
        created_by: user.uid,
        created_via: addedVia,
        comment: anmerkung,
        email: email,
        type: 'guestlist',
        list: activeList,
        status: 'confirmed',
        event: id,
        seller: organizerId,
      },
    );
  }

  const deleteGast = async (index) => {
      let g = gaesteliste[index]
      deleteDoc(
        doc(collection(firestore, 'tickets'), g.id),
      );
  }

  const resetInputs = () => {
    setName("");
    setAnmerkung("");
    setEmail("");
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true, // Treat the first row as headers
        complete: (result) => {
          for (let index = 0; index < result.data.length; index++) {
            if(result.data[index].Name.length !== 0){
              const name = result.data[index].Name
              const comment = result.data[index].Anmerkung
              addGast({name,comment,addedVia:"import"})
            } 
          }
        },
      });
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

  const isFullWidth = windowWidth < 768;

  return (
    <Modal
      isOpen={show}
      appElement={el}
      unselectable="true"
      autoFocus={false}
      className="modalContentSmall"
      overlayClassName="modalOverlayNormal"
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleClose}
      style={{
        content: {
            padding: isFullWidth ? "20px" : "25px 35px",
        },
      }}
    >
      <div className="col-flex" style={{gap:"2rem", height:"100%"}}>
        <div className="row-flex" style={{justifyContent:"space-between",alignItems:"center"}}>
          <div className="ModalHeader">Gästeliste</div>
          <div onClick={handleClose} className="cancelModal">{cancelIcon}</div>
        </div>
        <div className="modalSubtitle">Hier kannst du deine Gästeliste verwalten, die für deine Türsteher in der Scanapp einsehbar ist.</div>
        {permissions?.ticketing?.edit && <div className="row-flex flex-gap-1rem">
          <div className="col-flex flex-gap-1rem" style={{width: "100%"}}>
            {showAddBtn || !isFullWidth ? 
              <>
                <div className="large t-main font-semibold" style={{width:"fit-content"}}>Gast hinzufügen</div>
                <div className="guestlistBoxAdd">
                  <div className="InputColumn">
                    <div className="t-main">Name</div>
                    <input type="text" className='GuestInput' value={name} onChange={(e) => {setName(e.target.value)}} onKeyPress={(e) => {if(e.key === 'Enter' && anmerkung && name){
                      addGast({name,anmerkung,email,addedVia:"dashboard"});resetInputs()
                    }}} placeholder='z.B. Fabian + 2'/>
                  </div>
                  <div className="InputColumn">
                    <div className="t-main">Anmerkung</div>
                    <input type="text" className='GuestInput' value={anmerkung} onChange={(e) => {setAnmerkung(e.target.value)}} onKeyPress={(e) => {if(e.key === 'Enter' && anmerkung && name){
                      addGast({name,anmerkung,email,addedVia:"dashboard"});resetInputs()
                    }}} placeholder='z.B. Freunde'/>
                  </div>
                  <div className="InputColumn">
                    <div className="t-main">Liste</div>
                    <select className="GuestInput" value={activeList} onChange={(e) => setActiveList(e.target.value)}>
                      <option value="guestlist">Gästeliste</option>
                      <option value="friendslist">Freundesliste</option>
                      {gaesteliste.reduce((acc, cur) => [...acc, 'guestlist', 'friendslist'].includes(cur.list) ? acc : [...acc, cur.list], []).map(list => <option key={list} value={list}>{list}</option>)}
                      {![...gaesteliste.map(e => e.list), 'guestlist', 'friendslist', 'add'].includes(activeList) && <option value={activeList}>{activeList}</option>}
                      <option value="add">+ Neue Liste hinzufügen</option>
                    </select>
                    <Modal 
                      isOpen={activeList === 'add'}
                      appElement={Modal.setAppElement('#portal')}
                      unselectable="true"
                      autoFocus={false}
                      className="modalContentMember"
                      overlayClassName="modalOverlayNormal"
                      shouldFocusAfterRender={false}
                      shouldCloseOnOverlayClick={true}
                      onRequestClose={() => setActiveList('guestlist')}
                    >
                      <div className="col-flex" style={{gap: "2rem",height:"100%", alignItems: "center"}}>
                        <div className="col-flex" style={{width: "100%", gap: "20px"}}>
                          <div className="row-flex" style={{justifyContent:"space-between",alignItems:"center"}}>
                            <div style={{fontWeight: "600", fontSize: "var(--fontsize-enormous)", color: "var(--textMain)"}}>Liste hinzufügen</div>
                            <div onClick={() => setActiveList('guestlist')} style={{cursor:"pointer", color: "var(--textMain)", fontSize: "var(--fontsize-gigantic)"}}>{cancelIcon}</div>
                          </div>
                          <input className="TextInput" placeholder="z.B. Mitarbeiterliste" value={addList} onChange={e => setAddList(e.target.value)} onKeyDown={e => e.key === 'Enter' && setActiveList(addList) } type="text" />
                        </div>
                        <div className="addUserBtn" style={{cursor:"pointer", width: "calc(100% - 40px)"}} onClick={() => { setActiveList(addList) }}>Liste hinzufügen</div>
                      </div>
                    </Modal>
                  </div>
                  <div className="InputColumn">
                    <div className="t-main flex-gap-10">Email (optional) <FurtherInfo
                      explanation="Wenn du die Emailadresse angibst, bekommt der Gast ein mit der Scanapp kompatibles Ticket zugeschickt."
                    /></div>
                    <input type="text" className='GuestInput' value={email} onChange={(e) => {setEmail(e.target.value)}} onKeyPress={(e) => {if(e.key === 'Enter' && email && name){
                      addGast({name,anmerkung,email,addedVia:"dashboard"});resetInputs()
                    }}} placeholder='z.B. n@e.de'/>
                  </div>
                  <div className='hinzufuegenBtn Monsterrat_400 fontSize-16px white pointer' onClick={() => {addGast({name,anmerkung,email,addedVia:"dashboard"});resetInputs()}}>Hinzufügen</div>
                </div>
              </> : <div className='add-guest-btn' onClick={() => setShowAddBtn(true)}>{plusIcon} Hinzufügen</div>
            }
          </div>
        </div>}
        <div className="midContent">
          <div className='leftSideMid'>
            <input type="text" className="input-suchfeld" placeholder="z.B. Name" value={searchQuery} onChange={e => setSearchQuery(e.target.value)}></input>
            <div className="searchIconGuest" onClick={() => {document.getElementById("suchfeld-tickets")?.click()}}>{searchIcon}</div>
          </div>
          {permissions?.ticketing?.edit && <div className='rightSideMid'>
            <div className="Montserrat_400 fontSize-16px white">Gästelisteanzahl (ohne +) : {totalNumber}</div>
            <div className="csvBtnBox row-flex flex-gap-1rem">
              <input type="file" accept='.csv' id="CsvImportInput" style={{display:"none"}} onChange={handleFileUpload}/>
              <div className="csvBtn CSV" onClick={() => {setShowTemplate(true)}}>CSV-Liste importieren</div>
            </div>
          </div>}
        </div>
        <div className='table-tickets'>
          {isFullWidth 
          ? 
              <div className='guest-mobile-col' style={Object.keys(gaesteliste).length === 0 ? {overflowY:"unset"} : {}}>
                {Object.keys(gaesteliste).length !== 0 ? (
                  Object.keys(gaesteliste)
                    .filter(key =>
                      gaesteliste[key]?.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      gaesteliste[key]?.comment.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      gaesteliste[key]?.addedBy.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      gaesteliste[key]?.addedVia.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .length > 0 ? (
                      Object.keys(gaesteliste)
                        .filter(key =>
                          gaesteliste[key]?.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          gaesteliste[key]?.comment.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          gaesteliste[key]?.addedBy.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          gaesteliste[key]?.addedVia.toLowerCase().includes(searchQuery.toLowerCase())
                        )
                        .map((key, i) => {
                          return (
                            <div key={key} className='mobile-guest'>
                              <div className='row-flex justify_space_between' style={{alignItems: "center"}}>
                                <div>
                                  <div className="cursor-text font-semibold huge">{gaesteliste[key]?.first_name}</div>
                                  <div className="pointer t-secondary">{gaesteliste[key].liste}</div>
                                </div>
                                {permissions?.ticketing?.edit && (<div className="deleteBtn" onClick={() => {deleteGast(i)}}>{deleteIcon}</div>)}
                              </div>
                              <div>
                                <div className="cursor-text">Anmerkung: {gaesteliste[key]?.comment !== 0 && gaesteliste[key]?.comment !== "" ? gaesteliste[key]?.comment : "-"}</div>
                              </div>
                              <div onClick={() => setMoreInfosOpen(moreInfosOpen === key ? null : key)}>Mehr Infos {chevronDownIcon}</div>
                              {moreInfosOpen === key && (
                                <div>
                                  <div className="pointer">
                                    Email: {gaesteliste[key]?.email !== 0 && gaesteliste[key]?.email !== "" ? gaesteliste[key]?.email : "-"}
                                  </div>
                                  <div>Hinzugefügt von: {gaesteliste[key]?.addedBy}</div>
                                  <div>Hinzugefügt über: {gaesteliste[key]?.addedVia}</div>
                                </div>
                              )}
                            </div>
                          );
                        })
                    ) : (
                      <div>
                        <div className='p-20p text-center fontSize-20px'>
                          Keine Ergebnisse
                        </div>
                      </div>
                    )
                ) : (
                  <div>
                    <div className='p-20p text-center border-r-0' >
                      Es ist keiner in der Gästeliste eingetragen!
                    </div>
                  </div>
                )}
              </div>
          : <div className="table-ticket-overview" style={{overflow: "scroll", scrollbarWidth: "none"}} ref={tableRef}>
              <div className='table-tickets-head'>
                <div className='row-flex'>
                  <div className="table-tickets-box table-header-element">Name</div>
                  <div className="table-tickets-box table-header-element">Anmerkung</div>
                  <div className="table-tickets-box table-header-element">Email</div>
                  <div className="table-tickets-box table-header-element">Liste</div>
                  <div className="col-flex" style={{width: "20%", padding: "0px 20px", gap: "5px", alignItems: "center"}}>
                    <div className="table-header-element" style={{borderRight: "0px"}}>Hinzugefügt</div>
                    <div className='row-flex' style={{justifyContent: "center", width: "100%"}}>
                      <div className="table-header-element" style={{display: "flex", justifyContent: "center", padding: "0px 20px 10px 0px", width: "100%"}}>von</div>
                      <div style={{borderRight: "1px solid var(--textSecondary)"}}></div>
                      <div className="table-header-element" style={{display: "flex", justifyContent: "center", padding: "0px 0px 10px 20px", width: "100%"}}>über</div>
                    </div>
                  </div>
                  {permissions?.ticketing?.edit && <div className="table-tickets-box table-header-element" style={{borderRight: "0px", borderLeft: "1px solid var(--textSecondary)", width: "10.5%"}}>Entfernen</div>}
                </div>
              </div>
              <div className='table-tickets-body' style={Object.keys(gaesteliste).length === 0 ? {overflowY:"unset"} : {}}>
                {Object.keys(gaesteliste).length !== 0 ? (
                  Object.keys(gaesteliste)
                    .filter(key =>
                      gaesteliste[key]?.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      gaesteliste[key]?.comment.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      gaesteliste[key]?.addedBy.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      gaesteliste[key]?.addedVia.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .length > 0 ? (
                      Object.keys(gaesteliste)
                        .filter(key =>
                          gaesteliste[key]?.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          gaesteliste[key]?.comment.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          gaesteliste[key]?.addedBy.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          gaesteliste[key]?.addedVia.toLowerCase().includes(searchQuery.toLowerCase())
                        )
                        .map((key, i) => {
                          return (
                            <div key={key} className='table-items-row'>
                              <div className="cursor-text table-tickets-box">{gaesteliste[key]?.first_name}</div>
                              <div className="cursor-text table-tickets-box">{gaesteliste[key]?.comment !== 0 && gaesteliste[key]?.comment !== "" ? gaesteliste[key]?.comment : "-"}</div>
                              <div className="pointer table-tickets-box">{gaesteliste[key]?.email !== 0 && gaesteliste[key]?.email !== "" ? gaesteliste[key]?.email : "-"}</div>
                              <div className="pointer table-tickets-box">{gaesteliste[key].liste}</div>
                              <div className="table-tickets-box" style={{width: "10%"}}>{gaesteliste[key]?.addedBy}</div>
                              <div className="table-tickets-box" style={{width: "10%", borderRight: "0px"}}>{gaesteliste[key]?.addedVia}</div>
                              {permissions?.ticketing?.edit && (
                                <div className="table-tickets-box" style={{borderRight: "0px", borderLeft: "1px solid var(--textSecondary)", width: "10.5%"}}>
                                  <div className="deleteBtn" onClick={() => {deleteGast(i)}}>Entfernen {deleteIcon}</div>
                                </div>
                              )}
                            </div>
                          );
                        })
                    ) : (
                      <div>
                        <div className='p-20p text-center fontSize-20px' colSpan={6}>
                          Keine Ergebnisse
                        </div>
                      </div>
                    )
                ) : (
                  <div>
                    <div className='p-20p text-center border-r-0' colSpan={6}>
                      Es ist keiner in der Gästeliste eingetragen!
                    </div>
                  </div>
                )}
              </div>
            </div>}
          </div>  
        </div>
      <Modal 
        isOpen={showTemplate}
        appElement={el2}
        unselectable="true"
        autoFocus={false}
        className="modalContentNormal"
        overlayClassName="modalOverlayNormal"
        shouldFocusAfterRender={false}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseTemplate}
        style={{
            content: {
                backgroundColor: "var(--bg2)",
                width: "25vw",
                height: "fit-content",
                padding: "25px 30px",
            }}}>
        <div className="col-flex" style={{gap:"2rem",height:"100%"}}>
          <div className="col-flex ">
            <div className="row-flex" style={{justifyContent:"space-between",alignItems:"center"}}>
              <div style={{fontWeight: "600", fontSize: "var(--fontsize-enormous)", color: "var(--textMain)"}}>.csv Importieren</div>
              <div onClick={handleCloseTemplate} style={{cursor:"pointer", color: "var(--textMain)", fontSize: "var(--fontsize-gigantic)"}}>{cancelIcon}</div>
            </div>
            <div className="row-flex align_center">
              <div style={{color:"var(--lightgrey)", marginTop: "10px"}}>
                Der Import der CSV wird nur erfolgreich sein, wenn in das Format eingehalten wird. <br/>
                "Name" und "Anmerkung" müssen wie im unteren Beispiel in der ersten Reihe der ersten beiden Spalten stehen. <br/>
              </div>
            </div>
          </div>
          <div className="col-flex justify_center align_center" style={{gap:"2rem"}}>
            <img src={Template} width={230} height={100} alt=""/>
            <div className="hinzufuegenBtn Montserrat_400 fontSize-16px white" style={{cursor:"pointer"}} onClick={() => {setShowTemplate(false);document.getElementById('CsvImportInput').click()}}>Importieren</div>
          </div>
        </div>
      </Modal>    
    </Modal>
  )
}

export default Gaesteliste