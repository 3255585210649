import React, { Component, createContext } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { collection, doc, getDoc } from "firebase/firestore";
import { auth, firestore } from "../firebase";

export const UserContext = createContext({
  loaded: false,
  user: null,
  organizerId: null,
  isAdmin: false,
  permissions: {},
  setOrganizerId: null,
});

class UserProvider extends Component {
  state = {
    loaded: false,
    user: null,
    organizerId: null,
    isAdmin: false,
    permissions: {},
    setOrganizerId: null,
  };

  componentDidMount = async () => {
    onAuthStateChanged(auth, async user => {
      if(user) {
        const userDoc = await getDoc(doc(collection(firestore, 'users'), user.uid));
        if(userDoc.data()?.organizer) {
          return this.setState({
            loaded: true,
            user: user,
            organizerId: userDoc.data()?.organizer,
            isAdmin: false,
            permissions: userDoc.data()?.permissions,
            setOrganizerId: null,
          });
        }

        if(userDoc.data()?.role === 'admin') {
          const baseState = {
            loaded: true,
            user: user,
            organizerId: null,
            isAdmin: true,
            permissions: {
              events: {
                view: true,
                edit: true,
              },
              ticketing: {
                view: true,
                edit: true,
                setup: true,
                issue_guestlist: true,
                issue_standard: true,
              },
              statistics: {
                view: true,
              },
              marketing: {
                view: true,
                edit: true,
                setup: true,
              },
              website: {
                view: true,
                edit: true,
              },
              profile: {
                view: true,
                edit: true,
              },
              users: {
                view: true,
                edit: true,
              },
            },
            setOrganizerId: null,
          };
          return this.setState({
            ...baseState,
            setOrganizerId: (id) => this.setState({
              ...baseState,
              organizerId: id,
            })
          });
        }
        
        //Sign out if logged in with non-organizer account
        setTimeout(async () => {
          const userDoc = await getDoc(doc(collection(firestore, 'users'), user.uid));
          if(!userDoc.data()?.organizer && !userDoc.data()?.invitation && !user.isAnonymous) {
            await signOut(auth);
          }
        }, 3000);
      }
      
      this.setState({
        loaded: true,
        user: null,
        organizerId: null,
        isAdmin: false,
        permissions: {},
        setOrganizerId: null,
      });
    });
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;