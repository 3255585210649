import React from 'react'
import "../../../index.scss";
import Modal from 'react-modal'
import "./gaesteliste.css";
import { cancelIcon } from '../../../icons';
import { toast } from 'react-toastify';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebase';
import { hideLoader, showLoader } from '../../widgets/loaderdiv';

export default function InstantPayout({show, handleClose, eventId, payoutAmount}) {
    const el = Modal.setAppElement('#portal');

    const [loading, setLoading] = React.useState(false);

    const submit = () => {
        const payoutInstantly = httpsCallable(functions, "payments-payoutInstantly");
        setLoading(true);
        showLoader();
        payoutInstantly({
            event: eventId,
        }).then(() => {
            setLoading(false);
            hideLoader();
            toast.success("Die Sofortauszahlung wurde erfolgreich beantragt.");
            handleClose();
        }).catch(() => {
            setLoading(false);
            hideLoader();
            toast.error("Es ist ein Fehler aufgetreten, bitte versuche es später erneut.");
            handleClose();
        });
    }

    return (
        <Modal 
            isOpen={show}
            appElement={el}
            unselectable="true"
            autoFocus={false}
            className="modalContentNormal"
            overlayClassName="modalOverlayNormal"
            shouldFocusAfterRender={false}
            shouldCloseOnOverlayClick={true}
            onRequestClose={handleClose}
            style={{
                content: {
                    backgroundColor: "var(--bg2)",
                    width: "40vw",
                    height: "fit-content",
                    padding: "25px 30px",
                }}}>
            <div className="col-flex" style={{gap:"2rem",height:"100%"}}>
            <div className="col-flex ">
                <div className="row-flex" style={{justifyContent:"space-between",alignItems:"center"}}>
                    <div style={{fontWeight: "600", fontSize: "var(--fontsize-enormous)", color: "var(--textMain)"}}>Sofortauszahlung</div>
                    <div onClick={handleClose} className="cancelModal">{cancelIcon}</div>
                </div>
                <div className="col-flex align_center big">
                    <div style={{color:"var(--lightgrey)", marginTop: "10px"}}> Der Auszahlungsbetrag von <span className='t-white font-semibold'>{payoutAmount?.toFixed(2).replace('.', ',')}€</span> wird leider erst 7 Tage nach dem letzten Ticketkauf vom Zahlungsanbieter freigegeben. </div>
                    <div style={{color:"var(--lightgrey)", marginTop: "10px"}}> Du kannst jedoch für 2,5% vom Auszahlungsbetrag eine Sofortauszahlung beantragen. </div>
                    <div style={{color:"var(--lightgrey)", marginTop: "10px"}}> Diese kannst du jederzeit beantragen, auch an Wochenenden und Feiertagen, und das Geld erscheint in der Regel innerhalb von 30 Minuten auf dem verbundenen Bankkonto. </div>
                </div>
            </div>
            <div className='flex col-flex align_center'>
                <button style={{backgroundColor: loading ? "var(--grey)" : "var(--blue)", color: "white", borderRadius: "5px", width: "calc(100% - 20px)", padding: "10px", alignItems: "center", textAlign: "center", cursor: "pointer", marginTop: "10px", fontSize: "var(--fontsize-large)", fontWeight: "600"}} onClick={submit} disabled={loading}>{loading ? 'Bitte warten...' : 'Sofortauszahlung beantragen (kostenpflichtig)'}</button>
                <div style={{color: "var(--textSecondary", marginTop: "5px"}}>Mit Klicken auf obigen Button stimmst du unseren <a href={"https://www.elgio.de/terms-of-service-dashboard"} target="_blank" rel="noopener noreferrer" id="AgbLink" disabled>AGBs</a> zu.</div>
            </div>
            </div>
        </Modal>
    )
}