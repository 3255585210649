import React, { useContext, useState } from 'react'
import Modal from 'react-modal'
import { useEffect } from 'react'
import "../../index.scss";
import "./ArtistEdit.css";
import { cancelIcon, soundcloudIcon, spofityIcon } from '../../icons'
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { firestore, storage } from '../../firebase';
import FirebaseStorageImage from '../functions/FirebaseStorageImage';
import { infoToast, successToast } from './toasts.tsx';
import { UserContext } from '../../providers/UserProvider';
import { useParams } from 'react-router-dom';
import { ref, uploadString } from 'firebase/storage';

export default function ArtistBearbeiten({artistId, show, handleClose, lineup, indexToChange}) {
    const el = Modal.setAppElement("#portal")

    const { organizerId } = useContext(UserContext);
    const { id } = useParams()
    const [originalArtistData, setOriginalArtistData] = useState(null);

    const [artistName, setArtistName] = useState("");
    const [artistSpotify, setArtistSpotify] = useState("");
    const [artistSoundcloud, setArtistSoundcloud] = useState("");
    const [artistImage, setArtistImage] = useState("");
    const [isArtistImageLocal,setIsArtistImageLocal] = useState(false);
    
    useEffect(() => {
        const fetchArtistData = async () => {
          try {
            const artistDocRef = doc(collection(firestore, 'artists'), artistId);
            const artistDoc = await getDoc(artistDocRef);
    
            if (artistDoc.exists()) {
                const artistData = artistDoc.data();
                setOriginalArtistData(artistData);
                setArtistName(artistData?.name ?? "");
                setArtistSpotify(artistData?.links?.spotify ?? "");
                setArtistSoundcloud(artistData?.links?.soundcloud ?? "");
                setArtistImage(artistData?.images?.[0] ?? "");
            } else {
              console.log('No such document!');
            }
          } catch (err) {
            console.error('Failed to fetch artist data:', err);
          }
        };
    
        if (artistId) {
          fetchArtistData();
        }
    }, [artistId]);

    const saveEdit = async () => {
    
        const originalSpotify = originalArtistData?.links?.spotify || "";
        const originalSoundcloud = originalArtistData?.links?.soundcloud || "";
        const originalImage = originalArtistData?.images?.[0] || "";
    
        const isNameChanged = originalArtistData?.name !== artistName;
        const isSpotifyChanged = originalSpotify !== artistSpotify;
        const isSoundcloudChanged = originalSoundcloud !== artistSoundcloud;
        const isImageChanged = isArtistImageLocal || originalImage !== artistImage;

        const hasChanges = isNameChanged || isSpotifyChanged || isSoundcloudChanged || isImageChanged;
    
        if (hasChanges) {
            let artistRef = doc(firestore, 'artists', artistId);
            if(originalArtistData?.agent !== organizerId) {
                artistRef = doc(collection(firestore, 'artists'));
            }

            //upload the image to firebase storage
            let imagePath = originalImage;
            if(isArtistImageLocal) {
                const storageRef = ref(storage, `artist_images/${artistRef.id}/${Date.now()}`);
                const uploadRes = await uploadString(storageRef, artistImage, 'data_url');
                imagePath = uploadRes.ref.fullPath;
            }

            const data = {
                ...originalArtistData,
                agent: organizerId,
                name: artistName || null,
                links: { spotify: artistSpotify || null, soundcloud: artistSoundcloud || null }, 
                images: [imagePath],
            };
            if(originalArtistData?.agent === organizerId) {
                // Update the artist directly if the organizer is the agent
                updateDoc(artistRef, data)
                .then(() => {
                    successToast("Änderungen wurden gespeichert");
                    handleClose();
                });
            } else {
                // Create a new artist document for manual check by ELGIO if the organizer is not the agent
                setDoc(artistRef, { 
                    ...data,
                    base: artistId,
                    view_count: 0,
                }).then(() => {
                    let newLineup = [...lineup]; 
                    newLineup[indexToChange] = artistRef.id;
                    
                    updateDoc(doc(firestore, "events", id), { lineup: newLineup }) 
                    .then(() => {
                        successToast("Änderungen wurden gespeichert");
                        handleClose();
                    });
                });
            }
        } else {
            infoToast("Du hast keine Änderungen gemacht!");
        }
    };
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isFullWidth = windowWidth < 768;

    const handleArtistImageChange = (event) => {
        const file = event.target.files[0];
        setIsArtistImageLocal(true);
        if (file) {
            var reader = new FileReader();
            reader.onload = function () {
                let output = document.getElementById('output_image');
                output.src = reader.result;
                document.getElementById("output_image").style.visibility = 'visible';
                setArtistImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCloseModal = () => {
        handleClose()
        setArtistName("");
        setArtistSpotify("");
        setArtistSoundcloud("");
        setArtistImage("");
        setIsArtistImageLocal(false);
    }

    return (
        <Modal
            isOpen={show}
            appElement={el}
            unselectable="true"
            autoFocus={false}
            className="modalContentSmall"
            overlayClassName="modalOverlayNormal"
            shouldFocusAfterRender={false}
            shouldCloseOnOverlayClick={true}
            onRequestClose={handleCloseModal}
            style={{
                content: {
                    minWidth: "250px",
                    maxWidth: isFullWidth ? "75vw" : "350px",
                    height: "fit-content",
                    padding: isFullWidth ? "20px" : "25px",
                },
            }}
        >
            <div className="mainBoxScan">
                <div className="headerBox">
                    <div className="artisteditheader">
                        <div className="ModalHeader">Künstler bearbeiten</div>
                        <div onClick={handleClose} className="cancelModal">{cancelIcon}</div>
                    </div>
                    <div className="artistEditSubheader"> Passe Angaben zu diesem Künstler an </div>
                </div>
                <div className="artistEditContent">
                    <div className='col-flex gap-4'>
                        <div className='w-100 col-input'>
                            <div className='input-title'>Künstlername</div>
                            <input
                                name="Name"
                                className="w-100 TextInputModal"
                                placeholder="z.B. SeraFina200"
                                id="ArtistNameInput"
                                key={"ArtistInput"}
                                value={artistName ?? ""}
                                onChange={(e) => setArtistName(e.target.value)}
                            ></input>
                        </div>
                        <div className='w-100 col-input'>
                            <div className='input-title'>Bild</div>
                            <div className="row-flex align_center flex-gap-1rem">
                                <input type='file'
                                // ! TODO: replace style tag with className="visibility-hidden" when being merged on 'main' !
                                style={{visibility:"hidden", position:"absolute"}}
                                id='artistImageInput'
                                accept="image/*"
                                onChange={handleArtistImageChange}
                                />
                                <div
                                // ! TODO: replace style tag with className="w-100p h-100p bg-color-unset border-1p-white" when being merged on 'main' ! 
                                style={{width:"100px",height:"100px",border:"1px solid white"}}
                                // className="w-100p h-100p circle"
                                className="circle ">
                                    { artistImage && !isArtistImageLocal ? <FirebaseStorageImage
                                    // ! TODO: replace style tag with className="mr-0 w-100p h-100p" when being merged on 'main' ! 
                                    style={{marginRight:"0px",width:"100px",height:"100px"}}
                                    // className="mr-0 w-100p h-100p circle"
                                    className="circle" 
                                    reference={artistImage}></FirebaseStorageImage> 
                                    :  
                                    <img src={""} 
                                        // ! TODO: replace style tag with className="mr-0 w-100p h-100p" when being merged on 'main' ! 
                                        style={{marginRight:"0px",width:"100px",height:"100px"}}
                                        className="mr-0 w-100p h-100p circle"
                                        id="output_image" alt="ArtistImage" 
                                     />
                                    }
                                </div>

                                <div 
                                    // ! TODO: replace style Tag with className="p-10p Button bg-color-blue" when being merged on 'main' !
                                    style={{backgroundColor:"var(--grey)",padding:"10px"}}
                                    // className="Button bg-color-blue"
                                    className="Button bg-color-blue"
                                    onClick={() => {document.getElementById("artistImageInput").click()}}
                                >{artistImage.length  > 0  || (isArtistImageLocal && document.getElementById("output_image").src !== "" )? "Bild ändern" : "Bild hinzufügen"}</div>
                            </div>
                        </div>
                        <div className='w-100  col-input'>
                            <div className='input-title'>Musiklinks</div>
                            <div className='gap-2 items-center align_center row-flex'>
                                <div 
                                // ! TODO: replace style tag with className="white w-30p" when being merged on 'main' !
                                style={{width:"30px"}}
                                // className="white w-30p"
                                className='white row-flex align_center justify_center '>{soundcloudIcon}</div>
                                <input
                                    name="Name"
                                    className="w-100 TextInputModal"
                                    placeholder="z.B. https://soundcloud.com/..."
                                    id="ArtistNameInput"
                                    key={"ArtistInput"}
                                    value={artistSoundcloud ?? ""}
                                    onChange={(e) => setArtistSoundcloud(e.target.value)}
                                ></input>
                            </div>
                            <div className='gap-2 items-center align_center row-flex'>
                                <div 
                                // ! TODO: replace style tag with className="white w-30p" when being merged on 'main' !
                                style={{width:"30px"}}
                                // className="white w-30p row-flex align_center"                                
                                className='white row-flex align_center justify_center'>{spofityIcon}</div >
                                <input
                                    name="Name"
                                    className="w-100 TextInputModal"
                                    placeholder="z.B. https://spotify.com/..."
                                    id="ArtistNameInput"
                                    key={"ArtistInput"}
                                    value={artistSpotify ?? ""}
                                    onChange={(e) => setArtistSpotify(e.target.value)}
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className="saveArtistButton" onClick={saveEdit}>Speichern</div>
                </div>
            </div>
        </Modal>
    )
}