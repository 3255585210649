import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import "../../../index.scss";
import "./formSuccess.css";
import "../events/event-erstellen.css";
import "./selectEvent.css";

import { searchIcon, plusIcon } from '../../../icons';
import logo from "../../../graphics/newIcon_white.png";
import FirebaseStorageImage from '../../functions/FirebaseStorageImage';
import { index } from '../../../algolia';

export default function PromoteEventSelect() {
    //search
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        (async () => {
            if (searchValue.length <= 1) {
                setSearchResults([]);
                return;
            }

            const { hits } = await index.search(searchValue, {
                filters: `visibility:"public" AND recordType:"event" AND start._seconds >= ${parseInt(Date.now() / 1000)}`, 
                hitsPerPage: 5,
            });
    
            setSearchResults(hits);
        })();
    }, [searchValue]);

    return <>
        <div className='event-form-wrapper-success'>
                <div className='formSuccessPage'>
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <img style={{ height: 40 }} alt="elgio icon" src={logo} />
                    </div>
                    <div className='formSuccessTitle'>Wähle das Event, das du bewerben willst</div>
                    <div className="nav-search">
                        <div className="nav-search-box">
                            {searchIcon}
                            <input className="nav-search-transparent" placeholder="Suche" value={searchValue} onChange={e => setSearchValue(e.target.value)} />
                        </div>
                        {searchResults.length > 0 && <div className="nav-search-results">
                            {searchResults.map(result => <Link className="nav-search-result" to={`/promote-event/${result.objectID.split('-')[1]}`}>
                                <FirebaseStorageImage reference={result.images?.length > 0 ? result.images[0] : null} className='nav-search-result-image' />
                                <div className="p-3 min-w-0 flex flex-col justify-center" style={{overflow: "hidden"}}>
                                    <div className="nav-search-name">{result.name}</div>
                                    {result.recordType === 'event' && <div style={{fontSize: "var(--fontsize-regular)", color: "var(--grey)"}}>
                                        <span className="t-blue">{new Date((result.start?._seconds??result.start?.seconds??0) * 1000)?.toLocaleDateString("de-DE", { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric' })}</span>
                                        {result.venue_data?.name && <span className="t-grey"> · {result.venue_data?.name}</span>}
                                    </div>}
                                </div>
                            </Link>)}
                        </div>}
                    </div>
                    <div>
                        <div className='t-white large font-semibold' style={{textAlign: "center", marginBottom: "20px"}}>Dein Event ist noch nicht auf unserer Plattform? Lade es hier hoch</div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                            <Link to={"/event-form"} className="oneMoreEvent">{plusIcon} Event hochladen</Link>
                        </div>
                    </div>
                </div>
        </div>
    </>;
}