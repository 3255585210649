/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from "react";
import "../home.css";
import "../../../index.scss";
import "./events.css";
import { Link, useNavigate } from "react-router-dom";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";
import EventBox from "../../widgets/eventbox";
import { deleteIcon, plusIcon } from "../../../icons";
import LoaderDivWithOverlay from "../../widgets/loaderdiv";
import { OrganizerContext } from "../../../providers/OrganizerProvider";
import { errorToast, successToast } from "../../widgets/toasts.tsx";

function MeineEvents() {
  const { organizerId, permissions } = useContext(UserContext);
  const navigate = useNavigate();
  const { hasRoleAdmin } = useContext(OrganizerContext)
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [eventDrafts, setEventDrafts] = useState([]);
  const [currentTab, setCurrentTab] = useState("upcoming");
  const [noDrafts, setNoDrafts] = useState(false);
  const [reloadDrafts, setReloadDrafts] = useState(false);
  const [reloadEvents, setReloadEvents] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [checkBoxesVisible,setCheckBoxesVisible] = useState(false)
  const [checkBoxValues,setCheckBoxValues] = useState([])
  const isFullWidth = windowWidth > 768;

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchAll();
  }, [organizerId]);

  useEffect(() => {
    if (reloadDrafts || reloadEvents) {
      fetchAll();
      setReloadDrafts(false);
      setReloadEvents(false);
    }
  }, [reloadDrafts, reloadEvents]);

  function fetchAll() {
    //upcoming
    onSnapshot(
      query(
        collection(firestore, "events"),
        where("organizer", "array-contains", organizerId),
        where("visibility", "in", ["public", "private"]),
        where("start", ">", new Date()),
        orderBy("start", "asc")
      ),
      (querySnapshot) => {
        setUpcomingEvents(querySnapshot.docs);
      }
    );
    //past
    onSnapshot(
      query(
        collection(firestore, "events"),
        where("organizer", "array-contains", organizerId),
        where("visibility", "in", ["public", "private"]),
        where("start", "<", new Date()),
        orderBy("start", "desc")
      ),
      (querySnapshot) => {
        setPastEvents(querySnapshot.docs);
      }
    );
    //drafts
    onSnapshot(
      query(
        collection(firestore, "events"),
        where("organizer", "array-contains", organizerId),
        where("visibility", "==", "draft"),
        orderBy("last_edited", "desc")
      ),
      (querySnapshot) => {
        const filteredEvents = querySnapshot.docs.filter((doc) => {
          const data = doc.data();
          return data.firstSubevent || !data.recurring_event;
        });

        setEventDrafts(filteredEvents);
        setNoDrafts(filteredEvents.length === 0);
      }
    );
  }

  const events = useMemo(() => {
    switch (currentTab) {
      case 'upcoming':
        return upcomingEvents;
      case 'past':
        return pastEvents;
      case 'drafts':
        return eventDrafts;
      default:
        return [];
    }
  }, [currentTab, upcomingEvents, pastEvents, eventDrafts]);

  
  const handleAddEvent2Delete = (id) => {
    if(checkBoxValues.map((value) => {return value.id === id}).includes(true)){
      setCheckBoxValues(checkBoxValues.filter((value) => {return value.id !== id}))
    }
    else{
      setCheckBoxValues((prev) => [...prev,{id:id,value:true}])
    }

  }

  const handleDeleteEvents = async () => {
    if(!window.confirm("Bist du dir sicher, dass du diese Events löschen willst?")) return
    let ids = checkBoxValues.map((value) => {return value.id})
    let deleted = 0
    ids.forEach(async (id,index)  => {
      await deleteDoc(doc(firestore,"events",id))
      .then(() => {
        deleted+= 1;
      })
      .catch((error) => {
        console.log(error)
      })
      if(index === ids.length - 1){
        if(deleted === ids.length){
          successToast(`${ids.length} Events gelöscht!`)
          setReloadEvents(true)
        }
        else if(deleted > 0 && deleted < ids.length){
          successToast(`${ids.length} Events gelöscht!`)
          let events_with_error = ids.length - deleted
          errorToast(`${events_with_error} Events konnten nicht gelöscht werden.`)
        }
      }
      
    })
    
  }

  const handleCheckBoxMultiDelete = () => {
    if(checkBoxesVisible) setCheckBoxValues([])
    setCheckBoxesVisible(!checkBoxesVisible)
  }



  return (
    <div className="content">
      <LoaderDivWithOverlay />
      <div id="breadcrumb-title-text" onClick={() => navigate(-1)}>Events</div>
      <div className="PageHeaderBox" style={{ flexDirection: "row" }}>
        <div>
          <div className="PageHeaderBoxTitle">Events</div>
          <div className="PageHeaderBoxSubtitle">Deine persönliche Event Übersicht</div>
        </div>
        {permissions?.events?.edit && <Link to="./event-erstellen">
          <div id="newEventButton">
            {plusIcon}
            <div className="buttonText">Neues Event</div>
          </div>
        </Link>}
      </div>
      <div className="EventsContentContainer row-flex flex-gap-1rem">
        <div className={`${isFullWidth ? "w-70pct " : "w-87pct "}EventBoxColumn`}>
          <div className="title-row">
            <div
              className={currentTab === 'upcoming' ? "beschreibungtitelActive" : "beschreibungtitelNotActive"}
              onClick={() => { setCurrentTab('upcoming') }}
            >
              {isFullWidth ? "Anstehende Events" : "Anstehende" }
            </div>
            <div className="beschreibungtitel">|</div>
            <div
              className={currentTab === 'past' ? "beschreibungtitelActive" : "beschreibungtitelNotActive"}
              onClick={() => { setCurrentTab('past') }}
            >
              {isFullWidth ? "Vergangene Events" : "Vergangene"}
            </div>
            {!isFullWidth && (
              <>
                <div className="beschreibungtitel">|</div>
                <div
                  className={currentTab === 'drafts' ? "beschreibungtitelActive" : "beschreibungtitelNotActive"}
                  onClick={() => { setCurrentTab('drafts') }}
                >
                  Entwürfe
                </div>
              </>
            )}
          </div>
          <div className={`EventBox${events?.length === 0 ? " align_center justify_center" : ""}`}>
            <div id="noUpcoming" className="animate__animated animate__fadeInDown" style={events.length === 0 ? { display: "block", color: "var(--grey)" } : { display: "none" }}>
              {(() => {
                switch (currentTab) {
                  case 'upcoming':
                    return "Du hast keine anstehenden Events!";
                  case 'past':
                    return "Du hast keine vergangenen Events!";
                  case 'drafts':
                    return "Du hast keine Entwürfe!";
                  default:
                    return "Du hast keine Events!";
                }
              })()}
            </div>
            {events.map((event) => {
              const noTicketing = !["standard", "hidden"].includes(event.data().ticket);
              return (
                <div id="item" key={event.id}>
                  <EventBox
                    pendingReview={event.data().pending_review}
                    id={event.id}
                    date={
                      event.data()?.start?.toDate() &&
                      (event.data().start.toDate().toLocaleDateString("de-DE", { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric' }) +
                        ' ab ' +
                        event.data().start.toDate().toLocaleTimeString("de-DE", { hour: '2-digit', minute: '2-digit' }) +
                        ' Uhr')
                    }
                    titel={event.data().name}
                    club={event.data().venue}
                    address={event.data().address}
                    shares={event.data().attendee_count ?? 0}
                    views={event.data().view_count ?? 0}
                    saved={event.data().bookmark_count ?? 0}
                    banner={(event.data().images?.length ?? 0) > 0 ? event.data().images[0] : null}
                    visibility={event.data().visibility}
                    noTicketing={noTicketing}
                    showStats
                    setReloadEvents={setReloadEvents}
                    startDateRaw={event.data()?.start?.toDate()}
                    canceled={event.data()?.canceled ?? false}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className={`DraftBoxColumn${!isFullWidth ? " d-none" : ""}`}>
          <div id="vorschauheader">Entwürfe</div>
          {hasRoleAdmin && <div className="row-flex flex-gap-1rem align_center justify_space_between">
              <label className="row-flex align_center justify_space_between w-80pct pointer">
                <span className="white fontSize-16px">Mehrere Events löschen</span>
                <input name="multiDeleteDraft" type="checkbox" value={checkBoxesVisible} onChange={handleCheckBoxMultiDelete} id="multiDelete"/>
              </label> 
            <button className={`w-fit h-fit p-7p Button pointer white fontSize-14px bg-red ${checkBoxValues.length > 0 ? "d-block" : "d-none"}`} onClick={handleDeleteEvents}>{deleteIcon}</button>        
          </div>}
          <div className="DraftBox" style={noDrafts ? { justifyContent: "center", alignItems: "center" } : { borderRadius: "5px" }}>
            {noDrafts ? (
              <div id="noUpcoming" className="animate_animated animate__fadeInDown" style={{ display: "block", color: "var(--grey)" }}>
                Du hast keine Entwürfe!
              </div>
            ) : (
              eventDrafts.map((event,index) => (
                <div id="draftItem" className="row-flex flex-gap-1rem align_center justify_center" key={event.id}>
                  {checkBoxesVisible && <input name="deleteDraft" type="checkbox" checked={checkBoxValues.map((value) => {return value.id === event.id}).includes(true)} onChange={() => {handleAddEvent2Delete(event.id)}} id="deleteDraft"/>}
                  <EventBox
                    onClick={(e) => {
                      handleAddEvent2Delete(event.id);
                    }}                    
                    visibility="draft"
                    date={event.data()?.start?.toDate()?.toLocaleDateString("de-DE")}
                    banner={(event.data().images?.length ?? 0) > 0 ? event.data().images[0] : null}
                    titel={event.data().name}
                    club={event.data().venue}
                    address={event.data().address}
                    event={event}
                    id={event.id}
                    setReloadDrafts={setReloadDrafts}
                    isDraft
                    checkBoxesVisible={checkBoxesVisible}
                    shouldntRedirect={checkBoxesVisible}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}


export default MeineEvents;