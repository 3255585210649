import React, { createContext, useContext } from "react";
import { collection, doc,  onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";
import { UserContext } from "./UserProvider";
import { useEffect } from "react";
import { useState } from "react";

export const OrganizerContext = createContext({
  hasCreatedProfile: null,
  organizerType: null,
  hasPro: null,
  hasRoleAdmin: null
});

export default function OrganizerProvider(props) {
    const { organizerId } = useContext(UserContext);
    const [hasCreatedProfile, setHasCreatedProfile] = useState(true);
    const [organizerType, setOrganizerType] = useState('standard');
    const [hasPro, setHasPro] = useState(false);
    const [hasRoleAdmin, setHasRoleAdmin] = useState(false);
    useEffect(() => {organizerId && onSnapshot(doc(collection(firestore, 'organizers'), organizerId), (snap) => {
        setHasCreatedProfile(!!snap.data().name);
        setOrganizerType(snap.data().type??'standard');
        setHasRoleAdmin(snap.data().role === 'admin');
        setHasPro(snap.data().membership_subscriptions?.some(e => e.startsWith('dashboard_pro')));
    })}, [organizerId]);

    return (
        <OrganizerContext.Provider value={ { hasCreatedProfile, organizerType, hasPro,hasRoleAdmin } }>
            {props.children}
        </OrganizerContext.Provider>
    );
}