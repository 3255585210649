import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "./statistics_widgets.css";


export default function LineGraph({ data, labels, colors }) {
    const [sum, setSum] = useState(0);

    const options = {
        plugins: { legend: { labels: { font: { size: 16 } } } },
        aspectRatio: 2,
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            y: { beginAtZero: true, border: { color: '#9b9b9b' }, grid: { color: '#4b4b4b', lineWidth: 0.5 } },
            x: { border: { color: '#9b9b9b' }, grid: { lineWidth: 0 } }
        }
    }

    const optionsPlaceholder = {
        plugins: { legend: { labels: { font: { size: 16 } } } },
        aspectRatio: 2,
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            y: { beginAtZero: true, border: { color: '#2c2c2c' }, grid: { color: '#2c2c2c', lineWidth: 0.5 } },
            x: { border: { color: '#2c2c2c' }, grid: { lineWidth: 0 } }
        }
    }

    const real = {
        labels,
        datasets: [{
            label: 'Anzahl',
            data,
            borderColor: colors,
            pointBorderColor: colors,
            pointBackgroundColor: colors,
            pointBorderWidth: 2,
            tension: 0.3,
            borderWidth: 3,
        }],
    }

    const placeholder = {
        labels,
        datasets: [{
            label: 'Anzahl',
            data: ["1", "2", "3", "2", "1", "2", "3", "2", "4", "1", "2", "3", "2", "1", "2", "3", "2", "4", "1", "2", "3", "2", "1", "2", "3", "2", "4", "1", "2", "3", "2", "4"],
            borderColor: "#2c2c2c",
            pointBorderColor: "#2c2c2c",
            pointBackgroundColor: "#2c2c2c",
            pointBorderWidth: 2,
            tension: 0.3,
            borderWidth: 3,
        }],
    }

    useEffect(() => { setSum(data.reduce((prev, curr) => prev + curr, 0)) }, [data]);

    return sum === 0
        ?
        <div className="statsPlaceholderBox">
            <div className="statsPlaceholderLine">Für diesen Zeitraum liegen noch keine richtigen Daten vor</div>
            <Line data={placeholder} options={optionsPlaceholder}></Line>
        </div>
        :
        <div style={{position: "relative"}}>
            <Line data={real} options={options}></Line>
        </div>
        ;
}